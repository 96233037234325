import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/creddebt`

export async function sendCredDebt(token, data) {
  const endPoint = `${ENDPOINT}/`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, data, { headers })
  return response.data
}
