const messages = {
  en: {
    translations: {
      a_crase: 'to the',
      about: 'About Us',
      above_the_same: 'Above exactly equal',
      action: 'Action',
      actionTemplates: 'Action Templates',
      activate_date: 'Activation Date',
      active_automations: 'Active Automations',
      active_connections: 'Active Connections',
      active_monitors: 'Active Monitors',
      active_users: 'Active Users',
      ad: 'Advertisement',
      add: 'Addition',
      add_option: 'Add Option',
      add_payment: 'Add Payment',
      add_question: 'Add Question',
      add_receipt: 'Add Receipt',
      add_wallet: 'Add Wallet',
      address: 'Address',
      admin: 'Administration',
      advance: 'Advance',
      affected: 'Affected',
      after: 'After',
      air_transport: 'Air Transport',
      ajust_limite: 'Limit Adjustment',
      ajust_saldo: 'Balance Adjustment',
      alert: 'Alert',
      alerts: 'Alerts',
      all: 'All',
      all_departments: 'All Departments',
      allies_of: 'Allies of',
      allRightsReserved: 'All rights reserved.',
      almost_there: 'Almost there',
      already_offset: 'Already Offset',
      already_registered: 'Already registered? Click here to log in',
      amount: 'Amount',
      amount_above_balance: 'Insufficient balance',
      and: 'and',
      and_more: 'and more',
      answer: 'Answer',
      ao: 'to the',
      approve: 'Approve',
      April: 'April',
      asc: 'Ascending',
      at: 'at',
      August: 'August',
      automations: 'Automations',
      awaiting_email_confirmation: 'Awaiting email confirmation!',
      b_order: 'Order',
      b_orders: 'Orders',
      back: 'Back',
      bairroInteresse: 'Neighborhood',
      balance: 'Balance',
      balance_available: 'Available Balance',
      banned: 'Banned',
      before_contact: 'Before contacting us',
      binary: 'Binary Point',
      birth: 'Birth',
      blind_man: 'Reversed',
      body_monthly:
        'Your Monthly fee has expired and your BOT TRADER has been deactivated, please pay the Payment Order and enjoy your benefits again',
      bonus_saldo_trader: 'Bonus Trader Balance',
      book_health: 'Book Health',
      but_possible_register: 'Even so, it is possible to register, but it will not be related to any referring friend.',
      buy_volume: 'Purchase Volume',
      buyer: 'Buyer',
      by: 'by',
      bye: 'Goodbye',
      call: 'Call',
      call_to: 'Call to',
      calls: 'Calls',
      cancel: 'Cancel',
      canceled: 'Canceled',
      career_plan: 'Career Plan',
      casa_verde_amarela: 'Green and Yellow House',
      cash_payment: 'Cash Payment',
      cash_payment_return: 'Cash Payment Return',
      cash_receipt: 'Cash Receipt',
      change_indicant: 'Change Referrer',
      change_photo: 'Change Photo',
      change_plan: 'Change Plan',
      check_payment: 'Check Payment',
      check_payment_return: 'Check Payment Return',
      check_receipt: 'Check Receipt',
      choose: 'Choose',
      cidadeInteresse: 'City',
      city: 'City',
      claims: 'Claims',
      clean: 'Clean',
      click_and_know: 'Click and Learn',
      click_to_copy_the_wallet: 'Click to Copy the Wallet',
      client: 'Client',
      clients: 'Clients',
      close: 'Closure',
      cnh: "Driver's License",
      cnhDate: "Driver's License Issuance Date",
      cnpj: 'CNPJ',
      cnpj_invalid: 'Invalid CNPJ',
      coin: 'Coin',
      color: 'Color',
      commercial: 'Commercial',
      company: 'Company Name',
      complement: 'Complement',
      comporRenda: 'Will contribute income with someone',
      compRenda: 'Type of Income Proof',
      confirm_approve: 'Confirm Approval',
      confirm_delete: 'Confirm Deletion',
      confirm_new_password: 'Confirm New Password',
      confirm_new_password_security: 'Confirm new security password',
      confirm_password: 'Confirm Access Password',
      confirm_password_security: 'Confirm security password',
      confirm_your_new_password: 'Enter your new password again',
      confirmed: 'Confirmed',
      confirmed_email: 'Confirmed Email',
      congratulations: 'Congratulations',
      contact: 'Contact',
      contract: 'Contract',
      copied: 'Copied',
      copy: 'Copy',
      copy_success: 'Copied successfully!',
      correspondences: 'Correspondences',
      countries: 'Countries',
      country: 'Country',
      cpf: 'CPF',
      cpf_invalid: 'Invalid CPF',
      create_order_success: 'Order Created Successfully!',
      created: 'Registered on',
      cred: 'Credit',
      cred_binario: 'Binary Point Credit',
      cred_deb: 'Credits/Debits',
      cred_game_donate: 'Game Donate Credit',
      cred_ind_direta: 'Direct Indication Credit',
      cred_ind_indireta: 'Indirect Indication Credit',
      cred_mandala: 'Mandala Credit',
      cred_pagar_com_saldo: 'Credit Pay with Balance',
      cred_pontos: 'Points Credit',
      cred_recb_trader: 'Receive Trader Credit',
      cred_renovacao: 'Renewal Credit',
      cred_saldo_disp: 'Available Balance Credit',
      cred_upgrade: 'Upgrade Credit',
      credit_card_payment: 'Credit Card Payment',
      credit_card_payment_return: 'Credit Card Payment Return',
      credit_card_receipt: 'Credit Card Receipt',
      current: 'Current',
      current_password: 'Current Password',
      current_wallet: 'Current Wallet',
      dashboard: 'Dashboard',
      data_added: 'Data added',
      data_saved: 'Data Saved',
      datas: 'Data',
      date: 'Date',
      date_first_installment: 'First Payment Date',
      ddi: 'Country Code',
      deb_de_trader: 'Trader Debit',
      deb_game_donate: 'Game Donate Debit',
      deb_saldo: 'Balance Debit',
      deb_saldo_disp: 'Available Balance Debit',
      deb_saldo_pendent: 'Pending Balance Debit',
      deb_val_indev: 'Indequate Value Debit',
      debit_card_payment: 'Debit Card Payment',
      debit_card_payment_return: 'Debit Card Payment Return',
      debit_card_receipt: 'Debit Card Receipt',
      debt: 'Debit',
      debt_td: 'Debt',
      December: 'December',
      decImpRenda: 'Declare Income Tax',
      deleted_document: 'Document has been deleted from your record',
      department: 'Department',
      dependents: 'Dependents',
      desc: 'Descendant',
      description: 'Description',
      details: 'Details',
      dev_econom: 'Economic Development',
      developer: 'Developer',
      directly_indicated: 'Directly Indicated',
      directly_indicateds: 'Directly Indicated',
      directs: 'Directs',
      district: 'Neighborhood/District',
      divorced: 'Divorced',
      doa_men_receb: 'Monthly Platform Donation Received',
      doacao_td: 'Donation',
      doctorate_complete: 'PhD - Complete',
      doctorate_incomplete: 'PhD - Incomplete',
      document: 'Document',
      document_already_registered: 'Document already registered',
      documents: 'Documents',
      donate: 'Donate',
      donor: 'Donor',
      dont_know_zip: "Don't know the ZIP code? Click here",
      dou_fe: 'I confirm that the information above is correct.',
      downloads: 'Download Files',
      drop_images: 'Click or Drag images here',
      due_date: 'Due Date',
      edit: 'Edit',
      edit_option: 'Edit option',
      edit_question: 'Edit question',
      email: 'Email',
      email_already_registered: 'Email already registered',
      email_and_or_password_wrong: 'Email and/or Password wrong!',
      email_for_login: 'This email will be your login',
      email_or_password_wrong: 'Email or password incorrect',
      employee: 'Employee',
      employees: 'Employees',
      empty: 'Empty',
      enter_your_new_password: 'Enter your new password',
      entrada: 'Do you have a down payment? If YES, how much',
      entries_until_today: 'Entries until today',
      error_confirm_password: 'Confirmation and New Password must be the same',
      error_email: 'Invalid Email Format',
      error_password: 'Incorrect Password',
      error_phone: 'Invalid Phone Number',
      estadoInteresse: 'State',
      estimate: 'Estimated',
      exchange: 'Exchange',
      expired: 'Expired',
      extorno_td: 'Reversal',
      extra: 'Extra',
      extract: 'Statement',
      extracts: 'Statements',
      fantasy: 'Fantasy',
      fatherName: "Father's Name",
      February: 'February',
      fee: 'Withdrawal Fee',
      feedback: 'Feedback',
      feminine: 'Female',
      fgts: 'FGTS Value',
      field_required: 'Mandatory Field',
      final: 'Final',
      finance: 'Financial',
      financial: 'Financial',
      financial_payment: 'Financial Payment',
      financial_payment_return: 'Financial Payment Return',
      financial_receipt: 'Financial Receipt',
      financing: 'Financing',
      financings: 'Financings',
      first_10_pages: 'First 10 pages',
      fisica: 'Individual',
      for_year: 'Per Year',
      forget_password: 'Forgot Password',
      forgot_password: 'Forgot password',
      form: 'Form',
      form_of_payment: 'Payment Method',
      found: 'Found',
      founds: 'Founds',
      free: 'Free',
      friend_indicated: 'Indicated by my friends',
      friend_indicateds: 'Indicated by my friends',
      fuel: 'Fuel',
      full_name: 'Full Name',
      funcPublico: 'Is a Civil servant',
      fundamental_complete: 'Elementary - Complete',
      fundamental_incomplete: 'Elementary - Incomplete',
      ganh_diario: 'Daily Earnings',
      gender: 'Gender',
      grand_total: 'Grand Total',
      group: 'Group',
      has_withdraw_today: 'A withdrawal has already been requested today',
      high: 'High',
      home: 'Home',
      how_create_binance_apikey: 'How to create the API Key on Binance',
      how_it_works: 'How It Works',
      how_to_call: 'How do you want to be called',
      id_td: 'Direct Indication',
      images: 'Images',
      in_construction: 'Under Construction',
      in_order: 'In order',
      in_stock: 'In stock',
      indicant: 'Referrer',
      indicated_by: 'You are being indicated by',
      indicated_not_found: 'The indicated referrer was not found...',
      indicateds: 'Indicated',
      indirects: 'Indirects',
      info: 'Information',
      info_contact: 'Contact Information',
      initial: 'Initial',
      insert_new: 'Insert New',
      installments: 'Installments',
      intelligence: 'Intelligence',
      interesse: 'What is the area of interest',
      invalid_contact: 'Invalid Contact Format',
      invalid_images: 'Invalid Images',
      invalid_token: 'Invalid Token',
      invite: 'Invite',
      invite_friends: 'Invite your friends',
      January: 'January',
      July: 'July',
      June: 'June',
      juridica: 'Legal person',
      know_exact_result: 'Do you know the exact value?',
      lead: 'Together we will be building a new future.',
      learn_more: 'Learn More',
      left: 'Left',
      left_preferential: 'Preferring the Left Side',
      legalNature: 'Legal Nature',
      level: 'Level',
      limite_80: 'You have reached {{perc}} of your limit.\n Improve and avoid the suspension of your earnings.',
      limite_excedido: 'Exceeded limit debit',
      linear: 'Linear',
      link_email: 'Click on the Link Sent to the Registered Email!',
      link_invite_copy: 'Link copied successfully',
      liquid: 'Liquid',
      list: 'List',
      loading: 'Loading',
      location: 'Location',
      locked: 'Locked',
      login: 'Login',
      logout: 'Logout',
      low: 'Low',
      male: 'Male',
      manage_users: 'Manage Users',
      manager: 'Manager',
      mandala: 'Mandala',
      mandalas: 'Mandalas',
      manufacturer: 'Manufacturer',
      March: 'March',
      maritalStatus: 'Marital Status',
      market: 'Market',
      married: 'Married',
      master_complete: "Master's Degree - Complete",
      master_incomplete: "Master's Degree - Incomplete",
      max_payment: 'Maximum Payment',
      May: 'May',
      message: 'Message',
      messageNotSent: 'Could not send your message',
      messageSent: 'Your message has been sent successfully',
      middle_complete: 'High School - Complete',
      middle_incomplete: 'High School - Incomplete',
      min_amount_error: 'The value must be at least: ',
      min_withdraw: 'Minimum Withdrawal Amount',
      missing: 'Missing',
      mission: 'Mission',
      mission_vision_values: 'Mission, Vision, and Values',
      model: 'Model',
      monitors: 'Monitors',
      monthly: 'Monthly',
      motherName: "Mother's Name",
      movements: 'Movements',
      msg_renovacao: 'You have reached your earnings limit, renew your package.',
      my_account: 'My Account',
      my_wallet: 'My Wallet',
      mystock: 'My Stock',
      name: 'Name',
      nascMaisVelhoRenda2: 'What is the birth date of the oldest person?',
      nationality: 'Nationality (Country of Birth)',
      naturalness: 'Place of Birth (City)',
      nature: 'Nature',
      network: 'Network',
      new: 'New',
      new_call: 'New Call',
      new_financing: 'New Financing',
      new_order: 'New Order',
      new_password: 'New Password',
      new_password_security: 'New security password',
      new_seller: 'New Seller',
      new_wallet: 'New Wallet',
      news: 'News',
      next: 'Next',
      nick: 'User (login)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS already registered',
      no: 'No',
      no_approve: 'Not yet approved',
      no_delete: 'Do not delete',
      no_literate: 'Not Literate',
      no_repeat: 'The data cannot be repeated',
      no_results_found: 'No results found',
      no_services_or_products_added: 'No services or products added',
      no_settings: 'Go to Settings and fill in your details.',
      none_friends_indicated: 'Have not indicated any friends yet',
      not_authorized: 'Not authorized',
      not_found: 'Not found',
      not_login: 'Could not log in, check the data and try again!',
      not_orders: 'No Payment Orders',
      not_permission: 'You do not have permission to access this page',
      not_registered: 'Not Registered Yet? Click here to Register',
      not_results: 'No Records Found',
      not_self_register: 'Could not register',
      not_today_entry: 'No entries today',
      notfound_ad: 'No ads found',
      November: 'November',
      number: 'Number',
      obs: 'Observations',
      obs_pay:
        'If your broker deducts fees from the amount sent, you must manually correct by adding the values of these fees.',
      October: 'October',
      of: 'of',
      off: 'Off',
      on_total: 'Of Total',
      open: 'Open',
      opening: 'Opening',
      operation: 'Operation',
      ordenation: 'Ordination',
      order: 'Order',
      order_book: 'Order Book',
      order_not_alowed_to_pay: 'Order not authorized to pay with balance',
      order_not_found: 'Order not found',
      order_payed: 'Order #{{id}} paid successfully',
      orders: 'Orders',
      other: 'Other',
      other_services_or_products: 'Other Services or Products',
      paid: 'Paid',
      paid_date: 'Payment Date',
      partial: 'Partially Paid',
      passport: 'Passport',
      password: 'Password',
      password_security: 'Security Password',
      pay: 'Pay',
      pay_exact: 'Pay the amount in {{coin}}',
      pay_generate: 'Generate payment',
      pay_selected: 'Pay Selected',
      pay_value_in: 'Pay the amount in',
      pay_with_balance: 'Pay with Balance',
      payable: 'Payable',
      payer: 'Payer',
      payment: 'Payment',
      ped_retirada: 'Withdrawal Request',
      ped_retirada_gan_diario: 'Daily Earnings Withdrawal Request',
      pendent: 'Pending',
      pendent_order: 'You have Pending Orders',
      people: 'People',
      person: 'Person',
      person_found_in_other_prefecture: 'Person already registered in another municipality',
      personal: 'Personal',
      personal_addresses: 'Addresses',
      personal_contacts: 'Contacts',
      personal_data: 'Personal Data',
      personal_infos: 'Information',
      personal_pcva: 'Green and Yellow House Program',
      phone: 'Phone Number',
      photo: 'Photo',
      pix: 'PIX',
      pix_payment: 'PIX Payment',
      pix_payment_return: 'PIX Payment Return',
      pix_receipt: 'PIX Receipt',
      pl_carreira: 'Career Plan',
      plan: 'Plan',
      plates: 'Plates',
      please_wait: 'Please wait',
      plus: 'Plus',
      point: 'Point',
      points: 'Points',
      portfolio: 'Portfolio',
      pos_complete: 'Postgraduate - Complete',
      pos_incomplete: 'Postgraduate - Incomplete',
      prefecture: 'City Hall',
      prefecture_addresses: 'Addresses',
      prefecture_contacts: 'Contacts',
      prefecture_data: 'City Hall Data',
      prefecture_infos: 'Information',
      prefectures: 'Prefectures',
      prev: 'Previous',
      price: 'Price',
      print: 'Print',
      privacy_policies: 'Privacy Policies',
      private_works: 'Private Works',
      profession: 'Profession',
      profile: 'Profile',
      program: 'Program',
      programs: 'Programs',
      project: 'Project',
      projects: 'Projects',
      public_works: 'Public Works',
      purchase: 'Purchase',
      qualify_binary: 'Binary Qualification',
      qualquer: 'Any',
      quantity: 'Quantity',
      quotation: 'Quotation',
      read: 'Read',
      receivable: 'Receive',
      recipient: 'Recipient',
      recommendations: 'Recommendations',
      redefine_password: 'Reset Password',
      register: 'Register',
      register_people: 'People Registration',
      register_wallet: 'Register a Wallet to make withdrawals',
      registers: 'Records',
      registers_users: 'User Registrations',
      reject: 'Reject',
      remaining: 'Remaining',
      remove: 'Remove',
      rendaIndividual: 'Individual Gross Income',
      renew: 'Renew',
      renew_mandala: 'Mandala Renewal',
      reply: 'Reply',
      reports: 'Reports',
      required_data_not_sent: 'Required data not sent',
      resend_email: 'Resend the email',
      residential: 'Residential',
      responded: 'Responded',
      restricao: 'Do you have any restrictions in your name? (SPC/Serasa)',
      result: 'Result',
      results: 'Results',
      resume_data: 'Summary of data',
      revoked: 'Revoked',
      rg: 'General Registry (Identity)',
      rgAgency: 'Issuer of ID',
      rgDate: 'ID Issuance Date',
      rgUf: 'State of ID',
      right: 'Right',
      right_preferential: 'Preferring the Right Side',
      roi: 'Daily Earnings',
      sale: 'Sale',
      saq_duplicado: 'Duplicate Withdrawal',
      save: 'Save',
      schooling: 'Education',
      search: 'Search',
      secretary: 'Secretary',
      security_password: 'Security Password',
      see: 'See',
      see_advents: 'See the ads',
      see_call: 'See call',
      see_details: 'See details',
      see_details_wallet: 'See Wallet Details',
      see_financing: 'See financing',
      see_order: 'See order',
      see_user: 'See Users',
      select_vehicle: 'Select the vehicle',
      self_register: 'Sign up',
      sell_volume: 'Sales Volume',
      seller: 'Seller',
      send_call: 'Send Call',
      send_to: 'Send to',
      September: 'September',
      settings: 'Settings',
      share_your_link: 'Share your Link',
      show: 'Display up to',
      show_from: 'Displaying from',
      side: 'Side',
      sign_in_to_start_your_session: 'Sign in to start your session',
      sign_up: 'Sign up',
      single: 'Single',
      sold: 'Sold',
      state: 'State',
      status: 'Status',
      strategies: 'Strategies',
      street: 'Street',
      subject: 'Subject',
      submit: 'Submit',
      submit_placeholder: 'Please fill in your email',
      subscribe: 'Subscribe to receive our news',
      subtitle: 'Subtitle',
      success_update_user: 'User updated successfully!',
      success_wallet: 'Wallet updated successfully!',
      suggestions: 'Suggestions',
      superior_complete: 'Higher Education - Complete',
      superior_incomplete: 'Higher Education - Incomplete',
      supervisor: 'Supervisor',
      support: 'Support',
      support_materials: 'Support Materials',
      suspended: 'Suspended',
      symbol: 'Symbol',
      symbols: 'Symbols',
      system: 'System',
      target: 'Target',
      telegram_chat_id: 'Telegram Chat ID',
      tempoCarteira: 'How long have you been registered with the wallet',
      terms: 'I accept the terms of use',
      text_hint_binance:
        'When creating the API key on Binance, enter the IP restrictions:\n172.105.78.139\nThen check the options:\nENABLE EUROPEAN OPTIONS\nENABLE SPOT & MARGIN TRADING',
      this_value_in: 'This value in',
      ticker_health: 'Ticker Health',
      title: 'Title',
      to: 'Until',
      to_pay: 'To pay',
      to_receive: 'To receive',
      today_entry: "Today's Entry",
      token_not_found_or_already_used: 'Token not found or already used',
      total: 'Total',
      total_entry: 'Total Entry',
      traderbot: 'Trader Bot',
      transf_credito: 'Transfer Credit',
      transf_debito: 'Transfer Debit',
      transfer_payment: 'Transfer Payment',
      transfer_payment_return: 'Transfer Payment Return',
      transfer_receipt: 'Transfer Receipt',
      transparency: 'Transparency',
      tx_adm_cred: 'Administrative Fee',
      tx_adm_deb: 'Administrative Fee',
      tx_retirada: 'Withdrawal Fee',
      tx_retirada_gan_diario: 'Daily Earnings Withdrawal Fee',
      type: 'Type',
      update: 'Update',
      update_wallet: 'Update Wallet',
      upgrade: 'Upgrade',
      used: 'Used',
      user: 'User',
      user_active: 'Active User',
      user_already_exists: 'User already exists!',
      user_and_or_password_wrong: 'User and/or Password wrong!',
      user_inactive: 'Inactive User',
      user_not_found: 'User not found',
      user_not_logged: 'User not logged in',
      user_or_password_wrong: 'User or password incorrect',
      users: 'Users',
      users_list: 'User List',
      valid_images: 'Valid images',
      valorRenda2: 'What is the value of the second income',
      value: 'Value',
      value_donate_on: 'Donation Amount in',
      value_financed: 'Financed Amount',
      value_installment: 'Installment amount',
      values: 'Values',
      vehicle: 'Vehicle',
      vehicles: 'Vehicles',
      view_order: 'View Order',
      vision: 'Vision',
      visitor: 'Visitor',
      voucher: 'Voucher',
      wait_confirm: 'Waiting for confirmation',
      waiting: 'Waiting',
      waiting_email: 'Registration was successful, now just confirm the email.',
      waiting_payment: 'Waiting for Payment',
      wallet: 'Wallet',
      wallet_generate: 'Generate Wallet',
      wallets: 'Wallets',
      want_donate: 'I want to donate',
      want_now_more_about: 'I want to know more about',
      warning: 'WARNING',
      we_are: 'We are',
      webhooks: 'Webhooks',
      welcome: 'Welcome',
      who_we_are: 'Who We Are',
      who_we_are_subtitle: 'A Brief description about',
      widower: 'Widower',
      will_expire_in: 'will expire in',
      with: 'with',
      withdraw: 'Withdrawal',
      withdraw_sended_email: 'Withdrawal requested, check your email and click the link to authorize!',
      withdrawal: 'Withdrawals',
      withdrawals: 'Withdrawals',
      year: 'Year',
      years: 'Years',
      yes: 'Yes',
      you_have: 'You have',
      your_country: 'Your Country',
      your_dashboard: 'This is your Dashboard.',
      your_link: 'Your Link',
      zipcode: 'ZIP Code',
      withdrawal_sended: 'Withdrawal sent',
      withdrawal_pending: 'Withdrawal already requested! \n Please wait for the payment to make another withdrawal.',
      limite_80_top:
        'You have reached {{perc}} of your limit.\n Anticipate your renewal and avoid the suspension of your earnings.',
      game: 'Game',
      games: 'Games',
      winning_numbers: 'Winning Numbers',
      your_numbers: 'Your Numbers',
      add_numbers: 'Add Numbers',
      next_draw: 'Next Draw',
      can_bet_until: 'Can bet until',
      your_bets: 'Your Bets',
      add_bet: 'Add Bet',
      bet: 'Bet',
      bets: 'Bets',
      numbers: 'Numbers',
      special_numbers: 'Special Numbers',
      resume: 'Summary',
      choosed_numbers: 'Chosen Numbers',
      choosed_special_numbers: 'Chosen Special Numbers',
      bets_finished: 'Bets Finished',
      prize_draw: 'Draw',
      balance_network: 'Network Balance',
      telegram: 'Telegram',
      logs: 'Logs',
      release_withdrawals: 'Release Withdrawals',
      bot_active: 'Release Bot',
      available: 'Available',
      verify: 'Verify',
      rescue: 'Rescue',
      awarded: 'Awarded',
      rescued: 'Rescued',
      repeat: 'Repeat',
      draw_date: 'Draw Date',
      bet_date: 'Bet Date',
      drawn_numbers: 'Drawn Numbers',
      awardeds_numbers: 'Awarded Numbers',
      no_award: 'No Prize',
      you_hit_number: 'You hit {{qty}} number',
      you_hit_numbers: 'You hit {{qty}} numbers',
      lottery: 'Lottery',
      lotteries: 'Lotteries',
      balls: 'Balls',
      special_balls: 'Special Balls',
      levy: 'Levy',
      major_awarded: 'Major Awarded',
      hits: 'Hits',
      draw_due: 'Limit for betting',
      close_game: 'Close Game',
      draw_due_greater_than_draw_date: 'Betting limit must be at least {{timeLimit}} minutes before the draw',
      draw_date_less_than_now: 'Draw Date must be greater than the current date by at least {{timeLimit}} minutes',
      balance_game: 'Balance for Games',
      your_balance: 'Your Balance',
      your_balance_total: 'Your Total Balance',
      game_package: '{{qty}} numbers (can win {{prize}} times)',
      choose_package: 'Choose the package',
      error_bet: 'Could not send the bet',
      do_complete: 'Complete',
      success_bet: 'Bet sent',
      you_bet_number: 'You bet {{qty}} numbers',
      winner_msg: 'and won {{prize}} times',
      prize: 'Prize',
      balance_not_enough: 'Insufficient balance',
      addbalance: 'Add Balance for Games',
      convertbalance: 'Convert Balances',
      convertbalance_title: 'Convert Available Balance to Game Balance',
      can_award: 'You can win',
      emission: 'Emission',
      validation: 'Validation',
      participant: 'Participant',
      concourse: 'Concourse',
      draw_hours: 'Draw Hours',
      qty_dezenas: 'Quantity of dozens',
      bet_amount: 'Bet Amount',
      share: 'Share',
      shared: 'Prepared for sharing',
      simulation: 'Simulation',
      valuePay: 'Amount to Pay',
      quotas_paid_success: 'Successful payment',
      paids_today: 'Payments Made Today',
      no_paids_today: 'No payments made today',
      perc: 'Percentage',
      define_percent: 'Set Percentage',
      success_update: 'Updated successfully',
      define_percent_next_payment: 'Set next payment percentage',
      better: 'Betting Consultant',
      lottery_consultant: 'Associate Consultant',
      create: 'Create',
      doclose: 'Close',
      lottery_updated: 'Lottery updated',
      lottery_created: 'Lottery created',
      award_packages: 'Award Packages',
      qty: 'Qty',
      multiplier: 'Multiplier',
      maxValue: 'Max value',
      limitRepeat: 'Limit repetition',
      draw_day: 'Draw day',
      day: 'day',
      hour: 'hour',
      minutes: 'minutes',
      ytb_channel_id: 'Youtube Channel ID',
      tutorial: 'Tutorial',
      video_url: 'Video URL',
      tutorial_created: 'Tutorial created successfully',
      tutorial_deleted: 'Tutorial deleted',
      tutorial_error_delete: 'Error deleting tutorial',
      tutorial_updated: 'Tutorial updated',
      tutorial_error_update: 'Error updating tutorial',
      english: 'English',
      spanish: 'Spanish',
      portuguese: 'Portuguese',
      select_language: 'Select Language',
      language: 'Language',
      add_balance_games: 'Add Balance for Games',
      consultant_indication: 'Consultant Indication',
      mensal_activation: 'Monthly Activation',
      legal: 'Legal',
      juridical: 'Legal Entity',
      official_channel: 'Official Channel',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      title_binary: 'Set the Binary percentages for each day',
      ok_perc_change: 'Percentages changed successfully',
      cred_deb_title: 'Credit or Debit balance',
      send: 'Send',
      voucher_pendent: 'Pending Voucher',
      leader_pendent: 'Pending Leader',
      voucher_approved: 'Approved Voucher',
      leader_approved: 'Approved Leader',
      no_binary_at_today: 'No Binary registered on this day',
      generate_file: 'Generate file',
      withdrawals_file_created: 'Withdrawals file created',
      withdrawals_sended: 'Withdrawal requests sent',
      see_generated_files: 'See generated files',
      files: 'Files',
      file: 'File',
      conversion: 'Conversion between balances',
      betting_commission: 'Betting Commission',
      be_a_consultant: 'Be a Lottery Consultant and earn much more',
      msg_be_a_consultant:
        'Global Profit Sharing from The Lotter.\nBonus for Indication (on package sales)\nBinary Bonus \nand much more.',
      auto_renovations: 'Automatic Renewals',
      auto_renove_binary: 'Binary Auto-renewal',
      auto_renove_quotas: 'Quotas Auto-renewal (220%)',
      auto_renove_anual: 'Annual Auto-renewal',
      on: 'On',
      change_success: 'Changed successfully',
      anual: 'Annual',
      only_monday: 'Withdrawals will be available every Monday',
      transferency: 'Transfer',
      max_amount: 'Maximum amount',
      info_better:
        '- Receive 100% of your entry pack and renewals in CREDITS for betting.\n\n- You can top up new betting credits whenever you wish.\n\n- Participate in every business plan, and can earn from referrals direct and indirect, Binary Bonus, Unilevel, Career Plan and awards.',
      info_consultant:
        '- DOES NOT RECEIVE CREDITS for bets, THE FOUNDING CONSULTANT sells his CREDITS for bets from his Entry Pack and renewals for The Lotter to negotiate them, and therefore receives UP TO 1% on weekdays on the value of his pack until it reaches 220 % and can then renew if you wish.\n\n- You can top up your betting credit whenever you wish.\n\n- Participate in every business plan, being able to earn from direct and indirect referrals, Binary Bonus, Unilevel, Career Plan and awards.',
      confirm: 'Confirm',
      send_new_token: 'Sent the confirmation to your email, click on your email to confirm',
      email_resended: 'Resent the confirmation to your email, click on your email to confirm',
      expired_send_new_token: 'Sent a new confirmation to your email, click on your email to confirm',
      become_a_consultant: 'Upgrade and earn much more',
      register_ok: 'Successfully registered',
      ticket_number: 'Ticket Number',
      conversion_to_bet: 'Conversion to Bet',
      conversion_balance_games: 'Conversion from Balance to Game Balance',
      conversion_balance_lotterprocoin: 'Conversion from Balance to Activations',
      conversion_lotterprocoin_games: 'Conversion from Activations to Game Balance',
      conta_corrente: 'Checking Account',
      balance_games: 'Game Balance',
      transf_debit_lotterProCoin: 'Transfer of Activations Balance',
      deb_saldo_lotterProCoin: 'Debit of Activations Balance',
      transf_credit_lotterProCoin: 'Credit Transfer to Activations',
      live_now: 'Live Now',
      offline: 'Waiting for Draw',
      tutorials: 'Tutorials',
      msg_renove_binary:
        'You have already completed another month of the last monthly activation and you do not have automatic monthly activation activated.\nThat is why your Binary is inactive.\nPay a request to add game balance in the amount of $25.00 to carry out the monthly activation.',
      deb_balance_games: 'Debit of Balance for Games',
      cred_saldo_lotterProCoin: 'Credit of Balance for Activations',
      live_hour: 'Live Hour',
      choose_side_preferential: 'Choose preferred side (currently: {{side}})',
      count: 'Count',
      historics: 'Historic',
      balance_lotterProCoin: 'Activation balance',
      msg_binary_tree_1: 'Press the desired user for 1 second to see the information',
      msg_binary_tree_2: 'Click on the desired user once to see the next ones in the binary.',
      change_security_password: 'Change Security Password',
      info_pass_security: 'The security password is used to carry out financial transactions',
      redefine_security_password: 'Redefine Security Password',
      redefined_password: 'Access password reset successfully!',
      click_to_enter: 'Click to enter',
      title_banner: 'Welcome to {{company}}, {{name}}!',
      descryption_banner: 'Copy your registration link below and share it with your friends to maximize your earnings',
      no_binaries: "Cannot display this user's network",
      invalid_wallet: 'Invalid wallet',
      valid_wallet: 'Valid license',
    },
  },
}
export { messages }
