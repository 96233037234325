const messages = {
  ru: {
    translations: {
      a_crase: '',
      about: 'О нас',
      above_the_same: 'Точно то же самое, что и выше',
      action: 'Действие',
      actionTemplates: 'Модели действий',
      activate_date: 'Дата активации',
      active_automations: 'Активная автоматизация',
      active_connections: 'Активные соединения',
      active_monitors: 'Активные мониторы',
      active_users: 'Активные пользователи',
      ad: 'Объявление',
      add: 'Присоединение',
      add_option: 'Добавить вариант',
      add_payment: 'Добавить платеж',
      add_question: 'Добавить вопрос',
      add_receipt: 'Добавить квитанцию',
      add_wallet: 'Добавить кошелек',
      address: 'Адрес',
      admin: 'Администрация',
      advance: 'Продвигать',
      affected: 'Достиг',
      after: 'После',
      air_transport: 'Воздушный транспорт',
      ajust_limite: 'Регулировка предела',
      ajust_saldo: 'Регулировка баланса',
      alert: 'Тревога',
      alerts: 'Оповещения',
      all: 'Все',
      all_departments: 'Все отделы',
      allies_of: 'Союзники',
      allRightsReserved: 'Все права защищены.',
      almost_there: 'Почти готово',
      already_offset: 'я уже компенсировал',
      already_registered: 'Уже зарегистрирован? Нажмите здесь и войдите',
      amount: 'Ценить',
      amount_above_balance: 'Недостаточно средств',
      and: 'Это',
      and_more: 'и более',
      answer: 'Ответ',
      ao: 'к',
      approve: 'Согласовать',
      April: 'апрель',
      asc: 'Восходящий',
      at: 'в',
      August: 'Август',
      automations: 'Автоматизация',
      awaiting_email_confirmation: 'Ждем подтверждения по электронной почте!',
      b_order: 'Заказ',
      b_orders: 'Заказы',
      back: 'Вернуться назад',
      bairroInteresse: 'Район',
      balance: 'Баланс',
      balance_available: 'Доступный баланс',
      banned: 'Запрещено',
      before_contact: 'Прежде чем связаться с нами',
      binary: 'Двойная точка',
      birth: 'Рождение',
      blind_man: 'Перевернутый',
      body_monthly:
        'Срок действия вашей ежемесячной платы истек, и ваш BOT TRADER был деактивирован. Оплатите платежное поручение и снова наслаждайтесь преимуществами.',
      bonus_saldo_trader: 'Бонус трейдерского баланса',
      book_health: 'КнигаЗдоровье',
      but_possible_register:
        'Зарегистрироваться по-прежнему можно, но вы не будете связаны ни с одним приглашенным другом.',
      buy_volume: 'Объем покупки',
      buyer: 'Покупатель',
      by: 'за',
      bye: 'Увидимся позже',
      call: 'Называется',
      call_to: 'Позвонить',
      calls: 'Вызовы',
      cancel: 'Отмена',
      canceled: 'Отменено',
      career_plan: 'Карьерная лестница',
      casa_verde_amarela: 'Желто-Зеленый Дом',
      cash_payment: 'Наличный расчет',
      cash_payment_return: 'Возврат наличного платежа',
      cash_receipt: 'Кассовый чек',
      change_indicant: 'Индикатор изменения',
      change_photo: 'Измени фотографию',
      change_plan: 'Изменить план',
      check_payment: 'Оплата чеком',
      check_payment_return: 'Возврат оплаты чеком',
      check_receipt: 'Квитанция по чеку',
      choose: 'Выбирать',
      cidadeInteresse: 'Город',
      city: 'Город',
      claims: 'Претензии',
      clean: 'Чистить',
      click_and_know: 'Нажмите и откройте для себя',
      click_to_copy_the_wallet: 'Нажмите, чтобы скопировать кошелек',
      client: 'Клиент',
      clients: 'Клиенты',
      close: 'Закрытие',
      cnh: 'CNH (лицензия)',
      cnhDate: 'Дата выпуска CNH',
      cnpj: 'НКНПЖ',
      cnpj_invalid: 'Неверный CNPJ',
      coin: 'Монета',
      color: 'Цвет',
      commercial: 'Коммерческий',
      company: 'Название компании',
      complement: 'Дополнение',
      comporRenda: 'Собираетесь ли вы зарабатывать деньги с кем-то?',
      compRenda: 'Тип подтверждения дохода',
      confirm_approve: 'Подтвердить одобрение',
      confirm_delete: 'Подтвердить удаление',
      confirm_new_password: 'Подтвердите новый пароль',
      confirm_new_password_security: 'Подтвердите новый пароль безопасности',
      confirm_password: 'Подтвердите пароль доступа',
      confirm_password_security: 'Подтвердите пароль безопасности',
      confirm_your_new_password: 'Введите новый пароль еще раз',
      confirmed: 'Подтвержденный',
      confirmed_email: 'Подтвержденный адрес электронной почты',
      congratulations: 'Поздравления',
      contact: 'Контакт',
      contract: 'Договор',
      copied: 'Скопировано',
      copy: 'Копировать',
      copy_success: 'Скопировано успешно!',
      correspondences: 'Переписка',
      countries: 'Страны',
      country: 'Страна',
      cpf: 'КПФ',
      cpf_invalid: 'Неверный CPF',
      create_order_success: 'Заказ успешно создан!',
      created: 'Зарегистрировано в',
      cred: 'Кредит',
      cred_binario: 'Двойной балл',
      cred_deb: 'Кредиты/дебеты',
      cred_game_donate: 'Пожертвовать кредит на игру',
      cred_ind_direta: 'Прямой реферальный кредит',
      cred_ind_indireta: 'Косвенный реферальный кредит',
      cred_mandala: 'Мандала Кредит',
      cred_pagar_com_saldo: 'Кредитная оплата с балансом',
      cred_pontos: 'Кредитные баллы',
      cred_recb_trader: 'Трейдер получения кредита',
      cred_renovacao: 'Продление кредита',
      cred_saldo_disp: 'Доступный кредитный баланс',
      cred_upgrade: 'Кредит на обновление',
      credit_card_payment: 'Оплата кредитной картой',
      credit_card_payment_return: 'Возврат оплаты кредитной картой',
      credit_card_receipt: 'Квитанция по кредитной карте',
      current: 'Текущий',
      current_password: 'Текущий пароль',
      current_wallet: 'Текущий портфель',
      dashboard: 'Панель управления',
      data_added: 'Добавлены данные',
      data_saved: 'Сохраненные данные',
      datas: 'Данные',
      date: 'Дата',
      date_first_installment: 'Дата первого платежа',
      ddi: 'Код страны',
      deb_de_trader: 'Трейдерский долг',
      deb_game_donate: 'Дебет пожертвования игры',
      deb_saldo: 'Дебетовый баланс',
      deb_saldo_disp: 'Доступный дебетовый баланс',
      deb_saldo_pendent: 'Дебетовый остаток непогашенного остатка',
      deb_val_indev: 'Дебет неоплаченной суммы',
      debit_card_payment: 'Оплата дебетовой картой',
      debit_card_payment_return: 'Возврат оплаты дебетовой картой',
      debit_card_receipt: 'Квитанция на дебетовую карту',
      debt: 'Долг',
      debt_td: 'Дебет',
      December: 'Декабрь',
      decImpRenda: 'Декларировать подоходный налог',
      deleted_document: 'Документ был удален из вашей записи',
      department: 'Отделение',
      dependents: 'Иждивенцы',
      desc: 'Вниз',
      description: 'Описание',
      details: 'Подробности',
      dev_econom: 'Экономическое развитие',
      developer: 'Разработчик',
      directly_indicated: 'Прямо указано',
      directly_indicateds: 'Направлено напрямую',
      directs: 'Прямой',
      district: 'Район/район',
      divorced: 'В разводе',
      doa_men_receb: 'Получено ежемесячное пожертвование на платформу',
      doacao_td: 'Пожертвование',
      doctorate_complete: 'Докторантура - полная',
      doctorate_incomplete: 'Докторантура - неполная',
      document: 'Документ',
      document_already_registered: 'Документ уже зарегистрирован',
      documents: 'Документы',
      donate: 'Пожертвовать',
      donor: 'Донор',
      dont_know_zip: 'Не знаете почтовый индекс? кликните сюда',
      dou_fe: 'Я подтверждаю, что приведенная выше информация верна.',
      downloads: 'Скачать файлы',
      drop_images: 'Нажмите или перетащите изображения сюда',
      due_date: 'Зрелость',
      edit: 'Редактировать',
      edit_option: 'Редактировать вариант',
      edit_question: 'Изменить вопрос',
      email: 'Электронная почта',
      email_already_registered: 'Электронная почта уже зарегистрирована',
      email_and_or_password_wrong: 'Неправильный адрес электронной почты и/или пароль!',
      email_for_login: 'Этот адрес электронной почты будет вашим логином',
      email_or_password_wrong: 'Неверный адрес электронной почты или пароль',
      employee: 'Сотрудник',
      employees: 'Сотрудники',
      empty: 'Пустой',
      enter_your_new_password: 'Введите новый пароль',
      entrada: 'Имеет ли он входное значение? Если ДА, какое значение',
      entries_until_today: 'Записи на сегодняшний день',
      error_confirm_password: 'Подтверждение и новый пароль должны быть одинаковыми.',
      error_email: 'Неверный формат электронной почты',
      error_password: 'неверный пароль',
      error_phone: 'Неправильный номер телефона',
      estadoInteresse: 'состояние',
      estimate: 'Оцененный',
      exchange: 'Замена',
      expired: 'Истекший',
      extorno_td: 'Разворот',
      extra: 'Дополнительный',
      extract: 'Извлекать',
      extracts: 'Экстракты',
      fantasy: 'Фантазия',
      fatherName: 'Имя Отца',
      February: 'февраль',
      fee: 'Комиссия за снятие средств',
      feedback: 'Возвращаться',
      feminine: 'Женский',
      fgts: 'Стоимость вашего ФГТС',
      field_required: 'Обязательное завершение',
      final: 'Финал',
      finance: 'Финансовый',
      financial: 'Финансовый',
      financial_payment: 'Финансовый платеж',
      financial_payment_return: 'Возврат финансового платежа',
      financial_receipt: 'Получение финансов',
      financing: 'Финансирование',
      financings: 'Финансирование',
      first_10_pages: 'Первые 10 страниц',
      fisica: 'Физическое лицо',
      for_year: 'Ежегодно',
      forget_password: 'Я забыл пароль',
      forgot_password: 'Забыли пароль',
      form: 'Форма',
      form_of_payment: 'Форма оплаты',
      found: 'Найденный',
      founds: 'Найденный',
      free: 'Бесплатно',
      friend_indicated: 'Рекомендовано моими друзьями',
      friend_indicateds: 'Рекомендовано моими друзьями',
      fuel: 'Топливо',
      full_name: 'Полное имя',
      funcPublico: 'является государственным служащим',
      fundamental_complete: 'Фундаментальный – полный',
      fundamental_incomplete: 'Фундаментальный – неполный',
      ganh_diario: 'Ежедневный доход',
      gender: 'Пол',
      grand_total: 'общий итог',
      group: 'Группа',
      has_withdraw_today: 'Вывод средств уже был запрошен сегодня',
      high: 'Высокий',
      home: 'Начинать',
      how_create_binance_apikey: 'Как создать ключ API на Binance',
      how_it_works: 'Как это работает',
      how_to_call: 'Как ты хочешь, чтобы тебя называли?',
      id_td: 'Прямое направление',
      images: 'Изображений',
      in_construction: 'В разработке',
      in_order: 'Чтобы',
      in_stock: 'В наличии',
      indicant: 'Индикатор',
      indicated_by: 'Вас направляет',
      indicated_not_found: 'Указанный реферер не найден...',
      indicateds: 'Номинанты',
      indirects: 'Косвенный',
      info: 'Информационные бюллетени',
      info_contact: 'Контактная информация',
      initial: 'Дом',
      insert_new: 'Введите новый',
      installments: 'Рассрочка',
      intelligence: 'Интеллект',
      interesse: 'Какой регион интересен',
      invalid_contact: 'Неверный формат контакта',
      invalid_images: 'Недопустимые изображения',
      invalid_token: 'Неверный токен',
      invite: 'Пригласить',
      invite_friends: 'Пригласить друзей',
      January: 'январь',
      July: 'Июль',
      June: 'Июнь',
      juridica: 'Законопослушный гражданин',
      know_exact_result: 'Знаете ли вы точную стоимость?',
      lead: 'Вместе мы построим новое будущее.',
      learn_more: 'Узнать больше',
      left: 'Левый',
      left_preferential: 'Предпочитаю левую сторону',
      legalNature: 'Правовая природа',
      level: 'Уровень',
      limite_80: 'Вы достигли {{perc}} вашего лимита.\n Внесите улучшения и избегайте приостановки вашего дохода.',
      limite_excedido: 'Дебетовый лимит превышен',
      linear: 'Линейный',
      link_email: 'Нажмите на ссылку, отправленную на зарегистрированный адрес электронной почты!',
      link_invite_copy: 'Ссылка для общего доступа успешно скопирована.',
      liquid: 'Жидкость',
      list: 'Список',
      loading: 'Загрузка',
      location: 'Расположение',
      locked: 'Заблокировано',
      login: 'Войти',
      logout: 'Выйти',
      low: 'Низкий',
      male: 'Мужской род',
      manage_users: 'Управление пользователями',
      manager: 'Менеджер',
      mandala: 'Мандала',
      mandalas: 'Мандалы',
      manufacturer: 'Производитель',
      March: 'Маршировать',
      maritalStatus: 'Семейное положение',
      market: 'Рынок',
      married: 'Женатый',
      master_complete: 'Степень магистра - полная',
      master_incomplete: 'Степень магистра - неполная',
      max_payment: 'Максимальная выплата',
      May: 'Может',
      message: 'Сообщение',
      messageNotSent: 'Не удалось отправить ваше сообщение',
      messageSent: 'Ваше сообщение было отправлено успешно',
      middle_complete: 'Средний — Полный',
      middle_incomplete: 'Средний – Неполный',
      min_amount_error: 'Значение должно быть как минимум:',
      min_withdraw: 'Минимальная сумма вывода',
      missing: 'Отсутствующий',
      mission: 'Миссия',
      mission_vision_values: 'Миссия, видение и ценности',
      model: 'Модель',
      monitors: 'Мониторы',
      monthly: 'Ежемесячно оплата',
      motherName: 'Имя матери',
      movements: 'Движения',
      msg_renovacao: 'Вы достигли лимита заработка, продлите пакет.',
      my_account: 'Мой счет',
      my_wallet: 'Мой бумажник',
      mystock: 'Мой запас',
      name: 'Имя',
      nascMaisVelhoRenda2: 'Какая дата рождения у старшего?',
      nationality: 'Гражданство (Страна рождения)',
      naturalness: 'Место рождения (Город рождения)',
      nature: 'Природа',
      network: 'Сеть',
      new: 'Новый',
      new_call: 'Новое призвание',
      new_financing: 'Новое финансирование',
      new_order: 'Новый заказ',
      new_password: 'Новый пароль',
      new_password_security: 'Новый пароль безопасности',
      new_seller: 'Новый продавец',
      new_wallet: 'Новый кошелек',
      news: 'Новости',
      next: 'Следующий',
      nick: 'Логин пользователя)',
      nis: 'ПИС/НИС',
      nis_already_registered: 'PIS/NIS уже зарегистрированы',
      no: 'Нет',
      no_approve: 'Еще не одобрено',
      no_delete: 'Не удалять',
      no_literate: 'Неграмотный',
      no_repeat: 'Данные не могут повторяться',
      no_results_found: 'результатов не найдено',
      no_services_or_products_added: 'Никаких услуг и товаров не добавлено',
      no_settings: 'Зайдите в Настройки и заполните свои данные.',
      none_friends_indicated: 'Я еще не пригласил друзей',
      not_authorized: 'Не авторизовано',
      not_found: 'Не найдено',
      not_login: 'Не удалось войти в систему, проверьте свои данные и повторите попытку!',
      not_orders: 'Нет платежных поручений',
      not_permission: 'Вы не имеете доступа к этой странице',
      not_registered: 'Еще не зарегистрированы? Нажмите здесь и зарегистрируйтесь',
      not_results: 'Записей не найдено',
      not_self_register: 'Невозможно зарегистрироваться',
      not_today_entry: 'У нас до сих пор не было билетов сегодня',
      notfound_ad: 'Объявления не найдены',
      November: 'ноябрь',
      number: 'Число',
      obs: 'Комментарии',
      obs_pay:
        'Если ваш брокер списывает комиссию с отправленной суммы, вам необходимо внести корректировку вручную, добавив суммы этих комиссий.',
      October: 'Октябрь',
      of: 'в',
      off: 'Выключенный',
      on_total: 'Из общего числа',
      open: 'Открыть',
      opening: 'Открытие',
      operation: 'Производительность',
      ordenation: 'Заказ',
      order: 'Заказ',
      order_book: 'Книга заказов',
      order_not_alowed_to_pay: 'Несанкционированный запрос на оплату остатка',
      order_not_found: 'Заказ не найден',
      order_payed: 'Заказ №{{id}} успешно оплачен',
      orders: 'Запросы',
      other: 'Другой',
      other_services_or_products: 'Другие услуги или продукты',
      paid: 'Выплаченый',
      paid_date: 'День оплаты',
      partial: 'Частично оплачено',
      passport: 'Заграничный пасспорт',
      password: 'Пароль',
      password_security: 'Пароль безопасности',
      pay: 'Платить',
      pay_exact: 'Заплатите сумму в {{coin}}',
      pay_generate: 'Создать платеж',
      pay_selected: 'Оплата выбрана',
      pay_value_in: 'Оплатите сумму в',
      pay_with_balance: 'Оплата с баланса',
      payable: 'Платить',
      payer: 'Плательщик',
      payment: 'Оплата',
      ped_retirada: 'Запрос на вывод средств',
      ped_retirada_gan_diario: 'Запрос на вывод ежедневного дохода',
      pendent: 'В ожидании',
      pendent_order: 'У вас есть отложенные ордера',
      people: 'Люди',
      person: 'Человек',
      person_found_in_other_prefecture: 'Лицо, уже зарегистрированное в другом муниципалитете',
      personal: 'Ребята',
      personal_addresses: 'Адреса',
      personal_contacts: 'Контакты',
      personal_data: 'Личные данные',
      personal_infos: 'Информация',
      personal_pcva: 'Программа «Зеленый и желтый дом»',
      phone: 'Номер телефона',
      photo: 'Фотография',
      pix: 'ПИКС',
      pix_payment: 'Оплата в PIX',
      pix_payment_return: 'Возврат оплаты в PIX',
      pix_receipt: 'Прием в PIX',
      pl_carreira: 'Карьерная лестница',
      plan: 'Плоский',
      plates: 'Тарелки',
      please_wait: 'пожалуйста, подождите',
      plus: 'Плюс',
      point: 'Точка',
      points: 'Точки',
      portfolio: 'портфолио',
      pos_complete: 'Lato Sensu - Полный',
      pos_incomplete: 'Lato sensu – неполный',
      prefecture: 'Ратуша',
      prefecture_addresses: 'Адреса',
      prefecture_contacts: 'Контакты',
      prefecture_data: 'Данные мэрии',
      prefecture_infos: 'Информация',
      prefectures: 'Ратуши',
      prev: 'Предыдущий',
      price: 'Цена',
      print: 'Распечатать',
      privacy_policies: 'Политика конфиденциальности',
      private_works: 'Частные работы',
      profession: 'Профессия',
      profile: 'Профиль',
      program: 'Программа',
      programs: 'Программное обеспечение',
      project: 'Проект',
      projects: 'Проекты',
      public_works: 'Общественные работы',
      purchase: 'Покупка',
      qualify_binary: 'Двойная квалификация',
      qualquer: 'Любой',
      quantity: 'Количество',
      quotation: 'Цена',
      read: 'Читать',
      receivable: 'Получать',
      recipient: 'Получатель',
      recommendations: 'Рекомендации',
      redefine_password: 'Переопределить пароль',
      register: 'Записывать',
      register_people: 'Регистрация лиц',
      register_wallet: 'Зарегистрируйте кошелек для вывода средств.',
      registers: 'Рекорды',
      registers_users: 'Регистрация пользователей',
      reject: 'Отклонять',
      remaining: 'Оставшийся',
      remove: 'Удалять',
      rendaIndividual: 'Индивидуальный валовой доход',
      renew: 'Продлить',
      renew_mandala: 'Обновление мандалы',
      reply: 'Ответить',
      reports: 'Отчеты',
      required_data_not_sent: 'Необходимые данные не отправлены',
      resend_email: 'Отправить письмо повторно',
      residential: 'Жилой',
      responded: 'Ответил',
      restricao: 'Есть ли у вас ограничения на имя? (SPC/Сераса)',
      result: 'Результат',
      results: 'Полученные результаты',
      resume_data: 'Сводка данных',
      revoked: 'Отменен',
      rg: 'Общая регистрация (идентификация)',
      rgAgency: 'эмитент РГ',
      rgDate: 'Дата выпуска РГ',
      rgUf: 'Состояние РГ',
      right: 'Верно',
      right_preferential: 'Предпочитаю правую сторону',
      roi: 'Ежедневный доход',
      sale: 'Распродажа',
      saq_duplicado: 'Двойной вывод средств',
      save: 'Сохранить',
      schooling: 'Образование',
      search: 'Поиск',
      secretary: 'Секретарь',
      security_password: 'Пароль безопасности',
      see: 'Чтобы увидеть',
      see_advents: 'Посмотреть рекламу',
      see_call: 'Посмотреть звонок',
      see_details: 'смотрите подробности',
      see_details_wallet: 'Просмотр сведений о кошельке',
      see_financing: 'Посмотреть финансирование',
      see_order: 'Посмотреть заказ',
      see_user: 'Просмотр пользователей',
      select_vehicle: 'Выберите автомобиль',
      self_register: 'регистр',
      sell_volume: 'Объем продаж',
      seller: 'Продавец',
      send_call: 'Отправить звонок',
      send_to: 'Отправить',
      September: 'Сентябрь',
      settings: 'настройки',
      share_your_link: 'Поделитесь своей ссылкой',
      show: 'Показать до',
      show_from: 'Отображение с',
      side: 'Сторона',
      sign_in_to_start_your_session: 'Войдите, чтобы начать сеанс',
      sign_up: 'регистр',
      single: 'Одинокий',
      sold: 'Продал',
      state: 'состояние',
      status: 'Положение дел',
      strategies: 'Стратегии',
      street: 'Дорога',
      subject: 'Предмет',
      submit: 'Отправлять',
      submit_placeholder: 'Пожалуйста, заполните свой адрес электронной почты',
      subscribe: 'Подпишитесь, чтобы получать наши новости',
      subtitle: 'Подпись',
      success_update_user: 'Пользователь обновлен успешно!',
      success_wallet: 'Портфолио успешно обновлено!',
      suggestions: 'Предложения',
      superior_complete: 'Окончил',
      superior_incomplete: 'Неполное высшее',
      supervisor: 'Руководитель',
      support: 'Поддерживать',
      support_materials: 'Вспомогательные материалы',
      suspended: 'Приостановленный',
      symbol: 'Символ',
      symbols: 'Символы',
      system: 'Система',
      target: 'Цель',
      telegram_chat_id: 'Идентификатор чата Telegram',
      tempoCarteira: 'Как давно вы зарегистрировались в своем портфолио?',
      terms: 'Я принимаю условия использования',
      text_hint_binance:
        'При создании ключа API на Binance введите IP в ограничениях доступа:\n172.105.78.139\nЗатем выберите параметры:\n ВКЛЮЧИТЬ ЕВРОПЕЙСКИЕ ОПЦИИ\n ВКЛЮЧИТЬ СПОТНУЮ И МАРЖИННУЮ ТОРГОВЛЮ.',
      this_value_in: 'Это значение в',
      ticker_health: 'ТикерЗдоровье',
      title: 'Заголовок',
      to: 'До',
      to_pay: 'Выключать',
      to_receive: 'дебиторская задолженность',
      today_entry: 'Сегодняшняя запись',
      token_not_found_or_already_used: 'Токен не найден или уже использован',
      total: 'Общий',
      total_entry: 'Общий вход',
      traderbot: 'Бот Трейдер',
      transf_credito: 'Трансфертный кредит',
      transf_debito: 'Дебетовый перевод',
      transfer_payment: 'Оплата переводом',
      transfer_payment_return: 'Возврат оплаты переводом',
      transfer_receipt: 'Квитанция в передаче',
      transparency: 'Прозрачность',
      tx_adm_cred: 'Администрирование налогов',
      tx_adm_deb: 'Администрирование налогов',
      tx_retirada: 'Комиссия за снятие средств',
      tx_retirada_gan_diario: 'Комиссия за снятие ежедневной прибыли',
      type: 'Тип',
      update: 'Обновить',
      update_wallet: 'Обновить кошелек',
      upgrade: 'Улучшение',
      used: 'Использовал',
      user: 'Пользователь',
      user_active: 'Активный пользователь',
      user_already_exists: 'Пользователь уже существует!',
      user_and_or_password_wrong: 'Неверное имя пользователя и/или пароль!',
      user_inactive: 'Неактивный пользователь',
      user_not_found: 'Пользователь не найден',
      user_not_logged: 'Пользователь не авторизован',
      user_or_password_wrong: 'Неверное имя пользователя или пароль',
      users: 'Пользователи',
      users_list: 'Список пользователей',
      valid_images: 'Действительные изображения',
      valorRenda2: 'Сколько стоит второй доход',
      value: 'Ценить',
      value_donate_on: 'Сумма пожертвования в',
      value_financed: 'Сумма финансирования',
      value_installment: 'Стоимость рассрочки',
      values: 'Ценности',
      vehicle: 'Транспортное средство',
      vehicles: 'Транспортные средства',
      view_order: 'Посмотреть заказ',
      vision: 'Зрение',
      visitor: 'Посетитель',
      voucher: 'Ваучер',
      wait_confirm: 'Ожидание подтверждения',
      waiting: 'Ожидающий',
      waiting_email:
        'Регистрация прошла успешно, теперь все, что вам нужно сделать, это подтвердить свой адрес электронной почты.',
      waiting_payment: 'Ожидание оплаты',
      wallet: 'портфолио',
      wallet_generate: 'Создать портфолио',
      wallets: 'Кошельки',
      want_donate: 'Я хочу пожертвовать',
      want_now_more_about: 'Я хочу знать больше о',
      warning: 'ВНИМАНИЕ',
      we_are: 'Являются',
      webhooks: 'Вебхуки',
      welcome: 'Добро пожаловать',
      who_we_are: 'Кто мы',
      who_we_are_subtitle: 'Краткое описание',
      widower: 'вдовец',
      will_expire_in: 'истечет через',
      with: 'с',
      withdraw: 'Снятие',
      withdraw_sended_email:
        'Запрошен вывод средств, проверьте свою электронную почту и нажмите ссылку для авторизации!',
      withdrawal: 'Вывод средств',
      withdrawals: 'Вывод средств',
      year: 'Год',
      years: 'Годы',
      yes: 'Да',
      you_have: 'У вас есть',
      your_country: 'Твоя страна',
      your_dashboard: 'Это ваша панель управления.',
      your_link: 'Ваша ссылка',
      zipcode: 'Почтовый индекс',
      withdrawal_sended: 'Вывод отправлен',
      withdrawal_pending:
        'Вывод средств уже запрошен! \nПожалуйста, дождитесь платежа, чтобы сделать еще один вывод средств.',
      limite_80_top: 'Вы достигли {{perc}} вашего лимита.\n Ожидайте продления и избегайте приостановки вашего дохода.',
      game: 'Игра',
      games: 'Игры',
      winning_numbers: 'нарисованные числа',
      your_numbers: 'Ваши номера',
      add_numbers: 'Добавить номера',
      next_draw: 'Следующий розыгрыш',
      can_bet_until: 'Вы можете сделать ставку до',
      your_bets: 'Ваши ставки',
      add_bet: 'Добавить ставку',
      bet: 'Делать ставку',
      bets: 'Пари',
      numbers: 'Числа',
      special_numbers: 'Специальные номера',
      resume: 'Краткое содержание',
      choosed_numbers: 'Выбранные номера',
      choosed_special_numbers: 'Выбраны специальные номера',
      bets_finished: 'Расчетные ставки',
      prize_draw: 'Розыгрыш призов',
      balance_network: 'Сетевой баланс',
      telegram: 'Телеграмма',
      logs: 'Журналы',
      release_withdrawals: 'Выпуск вывода средств',
      bot_active: 'Релиз бота',
      available: 'Доступный',
      verify: 'Проверить',
      rescue: 'Спасать',
      awarded: 'Награжден',
      rescued: 'Спасен',
      repeat: 'Повторить',
      draw_date: 'Дата розыгрыша',
      bet_date: 'Дата ставки',
      drawn_numbers: 'нарисованные числа',
      awardeds_numbers: 'Призовые номера',
      no_award: 'Нет приза',
      you_hit_number: 'Вы правильно угадали номер {{qty}}',
      you_hit_numbers: 'Вы правильно угадали числа: {{qty}}.',
      lottery: 'Лотерея',
      lotteries: 'Лотереи',
      balls: 'мячи',
      special_balls: 'Специальные предложения',
      levy: 'коллекция',
      major_awarded: 'больше мячей',
      hits: 'хиты',
      draw_due: 'Лимит для ставок',
      close_game: 'Закрыть игру',
      draw_due_greater_than_draw_date: 'Лимит ставок должен быть не менее {{timeLimit}} минут до розыгрыша.',
      draw_date_less_than_now: 'Дата розыгрыша должна быть больше текущей даты как минимум на {{timeLimit}} минут.',
      balance_game: 'Баланс для игр',
      your_balance: 'Ваш баланс',
      your_balance_total: 'Ваш общий баланс',
      game_package: 'Номера: {{qty}} (можно выиграть {{prize}} раз)',
      choose_package: 'Выберите пакет',
      error_bet: 'Невозможно отправить ставку',
      do_complete: 'Завершить',
      success_bet: 'Ставка отправлена',
      you_bet_number: 'Вы ставите {{qty}} номера',
      winner_msg: 'и выиграл {{prize}} раз',
      prize: 'Премия',
      balance_not_enough: 'Недостаточно средств',
      addbalance: 'Добавить баланс в игры',
      convertbalance: 'Конвертировать балансы',
      convertbalance_title: 'Конвертировать доступный баланс в игровой баланс',
      can_award: 'Ты можешь выиграть',
      emission: 'Эмиссия',
      validation: 'Проверка',
      participant: 'Участник',
      concourse: 'Конкурс',
      draw_hours: 'Время розыгрыша',
      qty_dezenas: 'Количество десятков',
      bet_amount: 'Сумма ставки',
      share: 'Делиться',
      shared: 'Готов поделиться',
      simulation: 'Моделирование',
      valuePay: 'Сумма к оплате',
      quotas_paid_success: 'Успешный платеж',
      paids_today: 'Платежи, сделанные сегодня',
      no_paids_today: 'Платежей сегодня не было',
      perc: 'Процент',
      define_percent: 'Установить процент',
      success_update: 'Обновлено',
      define_percent_next_payment: 'Установить следующий процент платежа',
      better: 'Консультант по ставкам',
      lottery_consultant: 'Ассоциированный консультант',
      create: 'Создать',
      doclose: 'Закрыть',
      lottery_updated: 'Лотерей обновлен',
      lottery_created: 'Лотерей создан',
      award_packages: 'Пакеты наград',
      qty: 'Количество',
      multiplier: 'Множитель',
      maxValue: 'Максимальное значение',
      limitRepeat: 'Ограничение повтора',
      draw_day: 'День розыгрыша',
      day: 'день',
      hour: 'час',
      minutes: 'минут',
      ytb_channel_id: 'Идентификатор канала YouTube',
      tutorial: 'Обучение',
      video_url: 'Ссылка на видео',
      tutorial_created: 'Учебник создан',
      tutorial_deleted: 'Обучение удалено',
      tutorial_error_delete: 'Не удалось удалить обучение',
      tutorial_updated: 'Обучение обновлено',
      tutorial_error_update: 'Не удалось обновить обучение',
      english: 'Английский',
      spanish: 'Испанский',
      portuguese: 'Португальский',
      select_language: 'Выберите язык',
      language: 'Язык',
      add_balance_games: 'Добавить баланс в игры',
      consultant_indication: 'Назначение консультанта',
      mensal_activation: 'Активация ежемесячно',
      sunday: 'Воскресенье',
      monday: 'Второй',
      tuesday: 'Третий',
      wednesday: 'Четвертый',
      thursday: 'Пятый',
      friday: 'Пятница',
      saturday: 'Суббота',
      title_binary: 'Установите проценты Binary на каждый день',
      ok_perc_change: 'Проценты успешно изменены',
      cred_deb_title: 'Кредитный или дебетовый баланс',
      send: 'Отправлять',
      voucher_pendent: 'Ваучер ожидает рассмотрения',
      leader_pendent: 'Ожидаемый лидер',
      voucher_approved: 'Ваучер одобрен',
      leader_approved: 'Утвержденный лидер',
      no_binary_at_today: 'В этот день не зарегистрировано ни одного Binary.',
      generate_file: 'Создать файл',
      withdrawals_file_created: 'Файл вывода создан.',
      withdrawals_sended: 'Запросы на вывод отправлены',
      see_generated_files: 'Просмотр созданных файлов',
      files: 'Файлы',
      file: 'Файл',
      conversion: 'Конвертация между балансами',
      betting_commission: 'Комиссия по ставкам',
      be_a_consultant: 'Станьте консультантом по лотереям и зарабатывайте гораздо больше',
      msg_be_a_consultant:
        'Подразделение глобальной прибыли Lotter.\nРеферальный бонус (при пакетных продажах)\nБонус двойного балла\nи многое другое.',
      auto_renovations: 'Автоматическое продление',
      auto_renove_binary: 'Двухточечное самообновление',
      auto_renove_quotas: 'Самообновление целей (220%)',
      auto_renove_anual: 'Ежегодное самообновление',
      on: 'Связанный',
      change_success: 'Успешно изменено',
      anual: 'Ежегодно',
      only_monday: 'Вывод средств будет доступен каждый понедельник.',
      transferency: 'Передача',
      max_amount: 'Максимальное значение',
      info_better:
        '- Получите 100% своего стартового пакета и продление в КРЕДИТАХ для ставок.\n\n- Вы можете пополнять новые кредиты для ставок, когда захотите.\n\n- Участвуйте во всех бизнес-планах и можете зарабатывать на прямых и косвенных рефералах. , Бонус двойного балла, Unilevel, Карьерный план и награды.',
      info_consultant:
        '- НЕ ПОЛУЧАЕТ КРЕДИТЫ за ставки, КОНСУЛЬТАНТ-УЧРЕДИТЕЛЬ продает свои КРЕДИТЫ за ставки из своего Entry Pack и продлений для The Lotter для их согласования, и поэтому получает ДО 1% в будние дни от стоимости своего пакета, пока она не достигнет 220 % и затем можно продлить, если хотите.\n\n- Вы можете пополнить свой баланс ставок, когда захотите.\n\n- Участвуйте во всех бизнес-планах, имея возможность зарабатывать на прямых и косвенных рефералах, бонусе двойного балла, Unilevel, Карьерный план и награды.',
      confirm: 'Подтверждать',
      send_new_token:
        'Как только подтверждение будет отправлено на ваш адрес электронной почты, нажмите на свой адрес электронной почты, чтобы подтвердить',
      email_resended:
        'Подтверждение отправлено на ваш адрес электронной почты, нажмите на свой адрес электронной почты, чтобы подтвердить',
      expired_send_new_token:
        'Новое подтверждение отправлено на ваш адрес электронной почты. Нажмите на свой адрес электронной почты, чтобы подтвердить.',
      become_a_consultant: 'Обновите и получите гораздо больше',
      register_ok: 'Регистрация прошла успешно',
      ticket_number: 'Номер билета',
      conversion_to_bet: 'Конвертация в ставку',
      conversion_balance_games: 'Конвертация баланса в игровой баланс',
      conversion_balance_lotterprocoin: 'Конвертация баланса в Активации',
      conversion_lotterprocoin_games: 'Конвертация Активации в игровой баланс',
      conta_corrente: 'Текущий аккаунт',
      balance_games: 'Игровой баланс',
      transf_debit_lotterProCoin: 'Перевод баланса Активации',
      deb_saldo_lotterProCoin: 'Дебет баланса Активации',
      transf_credit_lotterProCoin: 'Перевести кредит в Активации',
      live_now: 'Прямая трансляция',
      offline: 'ждем розыгрыша',
      tutorials: 'Учебники',
      msg_renove_binary:
        'Вы уже завершили еще один месяц последней ежемесячной активации, и у вас не активирована автоматическая ежемесячная активация.\nВот почему ваш Binary неактивен.\nОплатите запрос на пополнение игрового баланса в размере 25,00 долларов США, чтобы выполнить ежемесячную активацию. .',
      deb_balance_games: 'Дебет баланса в игры',
      cred_saldo_lotterProCoin: 'Кредит баланса в Активации',
      live_hour: 'Время прямой трансляции',
      choose_side_preferential: 'Выберите предпочтительную сторону (в настоящее время: {{side}})',
      count: 'Количество',
      historics: 'Хронология',
      balance_lotterProCoin: 'Баланс активации',
      msg_binary_tree_1: 'ا Нажмите на нужного пользователя в течение 1 секунды, чтобы увидеть информацию',
      msg_binary_tree_2: 'Нажмите на нужного пользователя один раз, чтобы увидеть следующих в бинарном списке.',
      change_security_password: 'Сменить пароль безопасности',
      info_pass_security: 'Пароль безопасности используется для выполнения финансовых транзакций.',
      redefine_security_password: 'Повторно изменить пароль безопасности',
      redefined_password: 'Пароль доступа успешно сброшен!',
      click_to_enter: 'Нажмите, чтобы войти',
      title_banner: 'Добро пожаловать в {{company}}, {{name}}!',
      descryption_banner:
        'Скопируйте ссылку на регистрацию ниже и поделитесь ею с друзьями, чтобы максимизировать свой заработок.',
      no_binaries: 'Невозможно отобразить сеть этого пользователя',
      invalid_wallet: 'Неверный кошелек',
      valid_wallet: 'Действующая лицензия',
    },
  },
}
export { messages }
