import React from 'react'
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations'
import { i18n } from '../../utils/translates/i18n'

/**
 * props:
 * - data
 * - onEditClick
 */
export default function OrderRow({ o, editOrder, rule }) {
  return (
    <>
      <tr>
        <td>{i18n.t(o.type)}</td>
        <td>
          <a className={`btn-default btn-sm m-0 p-0`} href="#!">
            <span className="fw-bold">{o.user?.nick?.toUpperCase()}</span>

            <p style={{ whiteSpace: 'pre-line' }}>
              <small>{o.user?.name}</small>
            </p>
          </a>
        </td>
        <td>{FormateDate(o.createdAt, true)}</td>
        <td>{FormateFiatCurrency(o.total)}</td>
        <td>{FormateFiatCurrency(o.remaining)}</td>
        <td>
          <span className={`btn btn-sm btn-${o.statuses?.bg}`}>{i18n.t(o.statuses.status)}</span>
        </td>
        {/* <td>
					<span
						className={`btn btn-${
							o.payments && o.payments[0]?.status === 3
								? 'success'
								: o.payments && new Date(o.payments[0]?.dueDate) < new Date()
								? 'danger'
								: 'default'
						}`}>
						{o.dischargeDate
							? FormateDate(o.dischargeDate)
							: o.payments && o.payments[0]?.dueDate
							? FormateDate(o.payments[0]?.dueDate)
							: o.status === 1 || o.status === 2
							? i18n.t('waiting_payment')
							: i18n.t(o.statuses.status)}
					</span>
				</td> */}
        <td>
          {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
            <>
              {o.status === 1 || o.status === 2 ? (
                <button
                  type="button"
                  className={`btn btn-${o.statuses?.bg} btn-sm`}
                  title="Edit this Order"
                  data-bs-toggle="modal"
                  data-bs-target="#modalOrder"
                  onClick={() => editOrder(o)}>
                  <i className="fas fa-dollar me-2"></i> {i18n.t('pay')} #{o.id}
                </button>
              ) : (
                <span>#{o.id}</span>
              )}
            </>
          )}
        </td>
      </tr>
    </>
  )
}
