import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/quotas`

export async function simulation(token, percent) {
  const endPoint = `${ENDPOINT}/simulation`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { percent }, { headers })
  return response.data
}

export async function payQuotas(token, percent) {
  const endPoint = `${ENDPOINT}/payquotas`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { percent }, { headers })
  return response.data
}

export async function getQuotasPaid(token) {
  const endPoint = `${ENDPOINT}/paid`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.get(endPoint, { headers })
  return response.data
}
