const messages = {
		cn: { translations: {
  "a_crase": "这",
  "about": "关于我们",
  "above_the_same": "和上面完全一样",
  "action": "行动",
  "actionTemplates": "行动模型",
  "activate_date": "激活日期",
  "active_automations": "主动自动化",
  "active_connections": "活跃连接数",
  "active_monitors": "活动监视器",
  "active_users": "活跃用户",
  "ad": "公告",
  "add": "加入",
  "add_option": "添加选项",
  "add_payment": "添加付款方式",
  "add_question": "添加问题",
  "add_receipt": "添加收据",
  "add_wallet": "添加钱包",
  "address": "地址",
  "admin": "行政",
  "advance": "进步",
  "affected": "到达",
  "after": "后",
  "air_transport": "航空运输",
  "ajust_limite": "限制调整",
  "ajust_saldo": "平衡调整",
  "alert": "警报",
  "alerts": "警报",
  "all": "全部",
  "all_departments": "所有部门",
  "allies_of": "的盟友",
  "allRightsReserved": "版权所有。",
  "almost_there": "差不多了",
  "already_offset": "我已经补偿了",
  "already_registered": "已经注册？ 单击此处并输入",
  "amount": "价值",
  "amount_above_balance": "不充足的资金",
  "and": "这是",
  "and_more": "和更多",
  "answer": "回复",
  "ao": "到",
  "approve": "审批",
  "April": "四月",
  "asc": "上升",
  "at": "在",
  "August": "八月",
  "automations": "自动化",
  "awaiting_email_confirmation": "等待电子邮件确认！",
  "b_order": "命令",
  "b_orders": "命令",
  "back": "回去",
  "bairroInteresse": "邻里",
  "balance": "平衡",
  "balance_available": "可用余额",
  "banned": "禁止",
  "before_contact": "联系我们之前",
  "binary": "双点",
  "birth": "出生",
  "blind_man": "反转",
  "body_monthly": "您的月费已过期，您的BOT TRADER已被停用，请支付付款订单并再次享受您的福利",
  "bonus_saldo_trader": "交易者余额奖金",
  "book_health": "图书健康",
  "but_possible_register": "仍然可以注册，但您不会被链接到任何推荐的朋友。",
  "buy_volume": "购买量",
  "buyer": "买方",
  "by": "每",
  "bye": "回头见",
  "call": "被称为",
  "call_to": "拨电至",
  "calls": "召唤",
  "cancel": "取消",
  "canceled": "取消",
  "career_plan": "职业路线",
  "casa_verde_amarela": "黄绿屋",
  "cash_payment": "现金支付",
  "cash_payment_return": "现金支付返还",
  "cash_receipt": "现金收据",
  "change_indicant": "变化指标",
  "change_photo": "更改照片",
  "change_plan": "改变计划",
  "check_payment": "支票付款",
  "check_payment_return": "通过支票退回付款",
  "check_receipt": "支票收据",
  "choose": "来选择",
  "cidadeInteresse": "城市",
  "city": "城市",
  "claims": "索赔",
  "clean": "清洁",
  "click_and_know": "点击并发现",
  "click_to_copy_the_wallet": "点击复制钱包",
  "client": "客户",
  "clients": "顾客",
  "close": "关闭",
  "cnh": "离岸人民币（许可证）",
  "cnhDate": "离岸人民币发行日期",
  "cnpj": "CNPJ",
  "cnpj_invalid": "无效的 CNPJ",
  "coin": "硬币",
  "color": "颜色",
  "commercial": "商业的",
  "company": "公司名称",
  "complement": "补充",
  "comporRenda": "你要和某人一起赚钱吗？",
  "compRenda": "收入证明类型",
  "confirm_approve": "确认批准",
  "confirm_delete": "确认删除",
  "confirm_new_password": "确认新密码",
  "confirm_new_password_security": "确认新的安全密码",
  "confirm_password": "确认访问密码",
  "confirm_password_security": "确认安全密码",
  "confirm_your_new_password": "再次输入您的新密码",
  "confirmed": "确认的",
  "confirmed_email": "已确认的电子邮件",
  "congratulations": "恭喜你",
  "contact": "接触",
  "contract": "合同",
  "copied": "已复制",
  "copy": "复制",
  "copy_success": "复制成功！",
  "correspondences": "一致",
  "countries": "国家",
  "country": "国家",
  "cpf": "公积金",
  "cpf_invalid": "公积金无效",
  "create_order_success": "订单创建成功！",
  "created": "注册于",
  "cred": "信用",
  "cred_binario": "双积分积分",
  "cred_deb": "贷方/借方",
  "cred_game_donate": "游戏捐赠积分",
  "cred_ind_direta": "直接推荐信用",
  "cred_ind_indireta": "间接推荐信用",
  "cred_mandala": "曼陀罗信用",
  "cred_pagar_com_saldo": "余额信用支付",
  "cred_pontos": "积分",
  "cred_recb_trader": "信用接收交易者",
  "cred_renovacao": "更新信用",
  "cred_saldo_disp": "信用可用余额",
  "cred_upgrade": "信用升级",
  "credit_card_payment": "通过信用卡付款",
  "credit_card_payment_return": "信用卡付款退回",
  "credit_card_receipt": "信用卡收据",
  "current": "当前的",
  "current_password": "当前密码",
  "current_wallet": "目前的投资组合",
  "dashboard": "控制面板",
  "data_added": "添加数据",
  "data_saved": "保存的数据",
  "datas": "数据",
  "date": "日期",
  "date_first_installment": "首次付款日期",
  "ddi": "国家代码",
  "deb_de_trader": "交易者债务",
  "deb_game_donate": "游戏捐赠借方",
  "deb_saldo": "借方余额",
  "deb_saldo_disp": "借记可用余额",
  "deb_saldo_pendent": "未结余额借方",
  "deb_val_indev": "借记不当金额",
  "debit_card_payment": "通过借记卡付款",
  "debit_card_payment_return": "通过借记卡退回付款",
  "debit_card_receipt": "通过借记卡收据",
  "debt": "债务",
  "debt_td": "借方",
  "December": "十二月",
  "decImpRenda": "申报所得税",
  "deleted_document": "文档已从您的记录中删除",
  "department": "部门",
  "dependents": "家属",
  "desc": "向下",
  "description": "描述",
  "details": "细节",
  "dev_econom": "经济发展",
  "developer": "开发商",
  "directly_indicated": "直接指示",
  "directly_indicateds": "直接推荐",
  "directs": "直接的",
  "district": "邻里/区",
  "divorced": "离婚",
  "doa_men_receb": "收到每月平台捐款",
  "doacao_td": "捐款",
  "doctorate_complete": "博士学位 - 完成",
  "doctorate_incomplete": "博士学位 - 不完整",
  "document": "文档",
  "document_already_registered": "文件已注册",
  "documents": "文件",
  "donate": "捐",
  "donor": "捐赠者",
  "dont_know_zip": "不知道邮政编码？ 点击这里",
  "dou_fe": "我确认以上信息正确无误。",
  "downloads": "下载文件",
  "drop_images": "单击或将图像拖动到此处",
  "due_date": "到期",
  "edit": "编辑",
  "edit_option": "编辑选项",
  "edit_question": "编辑问题",
  "email": "电子邮件",
  "email_already_registered": "邮箱已注册",
  "email_and_or_password_wrong": "电子邮件和/或密码错误！",
  "email_for_login": "该电子邮件将作为您的登录名",
  "email_or_password_wrong": "错误的邮箱帐号或密码",
  "employee": "员工",
  "employees": "雇员",
  "empty": "空的",
  "enter_your_new_password": "输入您的新密码",
  "entrada": "有入门价值吗？ 如果是，值是多少",
  "entries_until_today": "迄今为止的条目",
  "error_confirm_password": "确认密码和新密码必须相同",
  "error_email": "电子邮件格式无效",
  "error_password": "密码错误",
  "error_phone": "无效的电话号码",
  "estadoInteresse": "状态",
  "estimate": "估计的",
  "exchange": "替代品",
  "expired": "已到期",
  "extorno_td": "逆转",
  "extra": "额外的",
  "extract": "提炼",
  "extracts": "提取物",
  "fantasy": "幻想",
  "fatherName": "父亲的名字",
  "February": "二月",
  "fee": "提款费",
  "feedback": "返回",
  "feminine": "女性化",
  "fgts": "您的 FGTS 的价值",
  "field_required": "强制完成",
  "final": "最终的",
  "finance": "金融的",
  "financial": "金融的",
  "financial_payment": "金融支付",
  "financial_payment_return": "财务付款返还",
  "financial_receipt": "财务收据",
  "financing": "融资",
  "financings": "融资",
  "first_10_pages": "前 10 页",
  "fisica": "自然人",
  "for_year": "每年",
  "forget_password": "我忘记了密码",
  "forgot_password": "忘记密码",
  "form": "形式",
  "form_of_payment": "付款方式",
  "found": "成立",
  "founds": "成立",
  "free": "自由的",
  "friend_indicated": "我的朋友推荐的",
  "friend_indicateds": "我的朋友推荐的",
  "fuel": "燃料",
  "full_name": "全名",
  "funcPublico": "是一名公务员",
  "fundamental_complete": "基础 - 完整",
  "fundamental_incomplete": "基础 - 不完整",
  "ganh_diario": "每日收益",
  "gender": "性别",
  "grand_total": "累计",
  "group": "团体",
  "has_withdraw_today": "今天已经请求提款",
  "high": "高的",
  "home": "开始",
  "how_create_binance_apikey": "如何在币安上创建 API 密钥",
  "how_it_works": "怎么运行的",
  "how_to_call": "你想被称为什么？",
  "id_td": "直接推荐",
  "images": "图片",
  "in_construction": "建设中",
  "in_order": "为了",
  "in_stock": "有存货",
  "indicant": "指标",
  "indicated_by": "您被推荐给",
  "indicated_not_found": "未找到指定的推荐人...",
  "indicateds": "提名者",
  "indirects": "间接",
  "info": "时事通讯",
  "info_contact": "联系信息",
  "initial": "家",
  "insert_new": "输入新的",
  "installments": "分期付款",
  "intelligence": "智力",
  "interesse": "感兴趣的区域是什么",
  "invalid_contact": "联系方式无效",
  "invalid_images": "无效图像",
  "invalid_token": "令牌无效",
  "invite": "邀请",
  "invite_friends": "邀请你的朋友",
  "January": "一月",
  "July": "七月",
  "June": "六月",
  "juridica": "法人",
  "know_exact_result": "你知道确切的价值吗？",
  "lead": "我们将共同建设新的未来。",
  "learn_more": "了解更多",
  "left": "左边",
  "left_preferential": "更喜欢左侧",
  "legalNature": "法律性质",
  "level": "等级",
  "limite_80": "您已达到限额的 {{perc}}。\n 进行改进并避免收入暂停。",
  "limite_excedido": "超过借记限额",
  "linear": "线性",
  "link_email": "点击发送到注册邮箱的链接！",
  "link_invite_copy": "分享链接复制成功",
  "liquid": "液体",
  "list": "列表",
  "loading": "加载中",
  "location": "地点",
  "locked": "被阻止",
  "login": "进入",
  "logout": "出去",
  "low": "低的",
  "male": "男性",
  "manage_users": "管理用户",
  "manager": "经理",
  "mandala": "曼陀罗",
  "mandalas": "曼陀罗",
  "manufacturer": "制造商",
  "March": "行进",
  "maritalStatus": "婚姻状况",
  "market": "市场",
  "married": "已婚",
  "master_complete": "硕士学位 - 完成",
  "master_incomplete": "硕士学位 - 不完整",
  "max_payment": "最高赔付额",
  "May": "可能",
  "message": "信息",
  "messageNotSent": "无法发送您的消息",
  "messageSent": "您的留言已成功发送",
  "middle_complete": "中 - 全",
  "middle_incomplete": "中等 - 不完整",
  "min_amount_error": "该值必须至少为：",
  "min_withdraw": "最低提款金额",
  "missing": "丢失的",
  "mission": "使命",
  "mission_vision_values": "使命、愿景和价值观",
  "model": "模型",
  "monitors": "显示器",
  "monthly": "每月支付",
  "motherName": "母亲的名字",
  "movements": "动作",
  "msg_renovacao": "您已达到收入限额，请续订您的套餐。",
  "my_account": "我的账户",
  "my_wallet": "我的钱包",
  "mystock": "我的股票",
  "name": "姓名",
  "nascMaisVelhoRenda2": "最大的出生日期是哪一天？",
  "nationality": "国籍（出生国）",
  "naturalness": "出生地（出生城市）",
  "nature": "自然",
  "network": "网络",
  "new": "新的",
  "new_call": "新的召唤",
  "new_financing": "新融资",
  "new_order": "新命令",
  "new_password": "新密码",
  "new_password_security": "新的安全密码",
  "new_seller": "新卖家",
  "new_wallet": "新钱包",
  "news": "消息",
  "next": "下一个",
  "nick": "用户登录）",
  "nis": "PIS/NIS",
  "nis_already_registered": "PIS/NIS 已注册",
  "no": "不",
  "no_approve": "尚未批准",
  "no_delete": "请勿删除",
  "no_literate": "不识字",
  "no_repeat": "数据不能重复",
  "no_results_found": "未找到结果",
  "no_services_or_products_added": "未添加任何服务或产品",
  "no_settings": "前往“设置”并填写您的详细信息。",
  "none_friends_indicated": "我还没有推荐任何朋友",
  "not_authorized": "未经授权",
  "not_found": "未找到",
  "not_login": "无法登录，请检查您的详细信息并重试！",
  "not_orders": "没有付款订单",
  "not_permission": "您没有权限访问此页面",
  "not_registered": "还没有注册？ 单击此处并注册",
  "not_results": "没有找到记录",
  "not_self_register": "无法注册",
  "not_today_entry": "今天我们还没买到票",
  "notfound_ad": "没有找到广告",
  "November": "十一月",
  "number": "数字",
  "obs": "评论",
  "obs_pay": "如果您的经纪商从发送的金额中扣除费用，您必须通过添加这些费用的金额来手动进行更正。",
  "October": "十月",
  "of": "在",
  "off": "离开",
  "on_total": "占总数的",
  "open": "打开",
  "opening": "开幕",
  "operation": "表现",
  "ordenation": "订购",
  "order": "命令",
  "order_book": "订单簿",
  "order_not_alowed_to_pay": "未经授权请求使用余额付款",
  "order_not_found": "未找到订单",
  "order_payed": "订单 #{{id}} 支付成功",
  "orders": "要求",
  "other": "其他",
  "other_services_or_products": "其他服务或产品",
  "paid": "支付",
  "paid_date": "发薪日",
  "partial": "部分付费",
  "passport": "护照",
  "password": "密码",
  "password_security": "安全密码",
  "pay": "支付",
  "pay_exact": "支付 {{coin}} 金额",
  "pay_generate": "生成付款",
  "pay_selected": "支付选择",
  "pay_value_in": "支付金额",
  "pay_with_balance": "用余额支付",
  "payable": "支付",
  "payer": "付款人",
  "payment": "支付",
  "ped_retirada": "提款请求",
  "ped_retirada_gan_diario": "要求提取每日收入",
  "pendent": "待办的",
  "pendent_order": "您有待处理的订单",
  "people": "人们",
  "person": "人",
  "person_found_in_other_prefecture": "已在另一个城市登记的人",
  "personal": "伙计们",
  "personal_addresses": "地址",
  "personal_contacts": "联系方式",
  "personal_data": "个人资料",
  "personal_infos": "信息",
  "personal_pcva": "绿屋和黄屋计划",
  "phone": "电话号码",
  "photo": "照片",
  "pix": "PIX",
  "pix_payment": "PIX 支付",
  "pix_payment_return": "PIX 中的付款退回",
  "pix_receipt": "在 PIX 中接收",
  "pl_carreira": "职业路线",
  "plan": "平坦的",
  "plates": "盘子",
  "please_wait": "请稍等",
  "plus": "加",
  "point": "观点",
  "points": "积分",
  "portfolio": "文件夹",
  "pos_complete": "Lato sensu - 完整",
  "pos_incomplete": "Lato sensu - 不完整",
  "prefecture": "市政府",
  "prefecture_addresses": "地址",
  "prefecture_contacts": "联系方式",
  "prefecture_data": "市政厅数据",
  "prefecture_infos": "信息",
  "prefectures": "市政厅",
  "prev": "以前的",
  "price": "价格",
  "print": "打印出",
  "privacy_policies": "隐私政策",
  "private_works": "私人工程",
  "profession": "职业",
  "profile": "轮廓",
  "program": "程序",
  "programs": "软件",
  "project": "项目",
  "projects": "项目",
  "public_works": "公共工程",
  "purchase": "购买",
  "qualify_binary": "双积分资格",
  "qualquer": "任何",
  "quantity": "数量",
  "quotation": "价格",
  "read": "读书",
  "receivable": "受到",
  "recipient": "接收者",
  "recommendations": "建议",
  "redefine_password": "重新定义密码",
  "register": "记录",
  "register_people": "人员登记",
  "register_wallet": "注册钱包进行提款",
  "registers": "记录",
  "registers_users": "用户注册",
  "reject": "拒绝",
  "remaining": "其余的",
  "remove": "去除",
  "rendaIndividual": "个人总收入",
  "renew": "更新",
  "renew_mandala": "曼陀罗更新",
  "reply": "回复",
  "reports": "报告",
  "required_data_not_sent": "未发送所需数据",
  "resend_email": "重新发送电子邮件",
  "residential": "住宅",
  "responded": "已回答",
  "restricao": "你的名字有限制吗？ （SPC/塞拉萨）",
  "result": "结果",
  "results": "结果",
  "resume_data": "数据汇总",
  "revoked": "已废除",
  "rg": "一般注册（身份）",
  "rgAgency": "RG发行人",
  "rgDate": "RG 发行日期",
  "rgUf": "RG 州",
  "right": "正确的",
  "right_preferential": "更喜欢右侧",
  "roi": "每日收益",
  "sale": "销售",
  "saq_duplicado": "双倍提款",
  "save": "保存",
  "schooling": "教育",
  "search": "搜索",
  "secretary": "秘书",
  "security_password": "安全密码",
  "see": "查看",
  "see_advents": "看广告",
  "see_call": "查看通话",
  "see_details": "查看具体信息",
  "see_details_wallet": "查看钱包详情",
  "see_financing": "查看融资",
  "see_order": "查看订单",
  "see_user": "查看用户",
  "select_vehicle": "选择车辆",
  "self_register": "登记",
  "sell_volume": "销售数量",
  "seller": "卖方",
  "send_call": "发送呼叫",
  "send_to": "发给",
  "September": "九月",
  "settings": "设置",
  "share_your_link": "分享您的链接",
  "show": "显示直到",
  "show_from": "显示自",
  "side": "边",
  "sign_in_to_start_your_session": "登录并开始您的会话",
  "sign_up": "登记",
  "single": "单身的",
  "sold": "卖",
  "state": "状态",
  "status": "地位",
  "strategies": "策略",
  "street": "路",
  "subject": "主题",
  "submit": "发送",
  "submit_placeholder": "请填写您的电子邮件",
  "subscribe": "注册以接收我们的新闻",
  "subtitle": "标题",
  "success_update_user": "用户更新成功！",
  "success_wallet": "作品集更新成功！",
  "suggestions": "建议",
  "superior_complete": "毕业了",
  "superior_incomplete": "不完全高等",
  "supervisor": "导师",
  "support": "支持",
  "support_materials": "支持材料",
  "suspended": "暂停",
  "symbol": "象征",
  "symbols": "符号",
  "system": "系统",
  "target": "目标",
  "telegram_chat_id": "电报聊天 ID",
  "tempoCarteira": "您在投资组合中注册多久了？",
  "terms": "我接受使用条款",
  "text_hint_binance": "在币安上创建 API 密钥时，在访问限制中输入 IP：\n172.105.78.139\n然后选择选项：\n 启用欧洲期权\n 启用现货和保证金交易",
  "this_value_in": "该值在",
  "ticker_health": "股票健康",
  "title": "标题",
  "to": "直到",
  "to_pay": "关闭",
  "to_receive": "应收账款",
  "today_entry": "今天的入场",
  "token_not_found_or_already_used": "未找到令牌或已使用令牌",
  "total": "全部的",
  "total_entry": "总参赛人数",
  "traderbot": "机器人交易者",
  "transf_credito": "转移学分",
  "transf_debito": "转账借方",
  "transfer_payment": "转账付款",
  "transfer_payment_return": "通过转账方式退回付款",
  "transfer_receipt": "转账收据",
  "transparency": "透明度",
  "tx_adm_cred": "管理税务",
  "tx_adm_deb": "管理税务",
  "tx_retirada": "提款费",
  "tx_retirada_gan_diario": "每日收益提现费用",
  "type": "类型",
  "update": "更新",
  "update_wallet": "更新钱包",
  "upgrade": "改进",
  "used": "用过的",
  "user": "用户",
  "user_active": "活跃用户",
  "user_already_exists": "用户已存在！",
  "user_and_or_password_wrong": "用户名和/或密码错误！",
  "user_inactive": "不活跃用户",
  "user_not_found": "未找到用户",
  "user_not_logged": "用户未登录",
  "user_or_password_wrong": "用户名或密码错误",
  "users": "用户",
  "users_list": "用户列表",
  "valid_images": "有效图像",
  "valorRenda2": "第二收入的价值是多少",
  "value": "价值",
  "value_donate_on": "捐赠价值",
  "value_financed": "融资金额",
  "value_installment": "分期付款价值",
  "values": "价值观",
  "vehicle": "车辆",
  "vehicles": "汽车",
  "view_order": "查看订单",
  "vision": "想象",
  "visitor": "游客",
  "voucher": "代金券",
  "wait_confirm": "等待确认",
  "waiting": "等待",
  "waiting_email": "注册成功，现在您需要做的就是确认您的电子邮件。",
  "waiting_payment": "等待付款",
  "wallet": "文件夹",
  "wallet_generate": "生成投资组合",
  "wallets": "钱包",
  "want_donate": "我想捐款",
  "want_now_more_about": "我想了解更多",
  "warning": "注意力",
  "we_are": "是",
  "webhooks": "网络钩子",
  "welcome": "欢迎",
  "who_we_are": "我们是谁",
  "who_we_are_subtitle": "简要说明",
  "widower": "鳏夫",
  "will_expire_in": "将到期于",
  "with": "和",
  "withdraw": "退出",
  "withdraw_sended_email": "请求提现，请检查您的电子邮件并点击链接进行授权！",
  "withdrawal": "提款",
  "withdrawals": "提款",
  "year": "年",
  "years": "年",
  "yes": "是的",
  "you_have": "你有",
  "your_country": "你的国家",
  "your_dashboard": "这是您的控制面板。",
  "your_link": "您的链接",
  "zipcode": "邮政编码",
  "withdrawal_sended": "提款已发送",
  "withdrawal_pending": "已请求提款！ \n请等待付款后再进行提款。",
  "limite_80_top": "您已达到 {{perc}} 限额。\n 预计续订并避免暂停您的收入。",
  "game": "游戏",
  "games": "游戏",
  "winning_numbers": "抽奖号码",
  "your_numbers": "你的号码",
  "add_numbers": "添加号码",
  "next_draw": "下一次抽奖",
  "can_bet_until": "您最多可以投注",
  "your_bets": "你的赌注",
  "add_bet": "添加赌注",
  "bet": "赌注",
  "bets": "投注",
  "numbers": "数字",
  "special_numbers": "特殊号码",
  "resume": "概括",
  "choosed_numbers": "所选号码",
  "choosed_special_numbers": "选择特殊号码",
  "bets_finished": "已结算投注",
  "prize_draw": "抽奖",
  "balance_network": "网络平衡",
  "telegram": "电报",
  "logs": "日志",
  "release_withdrawals": "释放提款",
  "bot_active": "发布机器人",
  "available": "可用的",
  "verify": "去检查",
  "rescue": "救援",
  "awarded": "荣获",
  "rescued": "获救",
  "repeat": "重复",
  "draw_date": "抽奖日期",
  "bet_date": "投注日期",
  "drawn_numbers": "抽奖号码",
  "awardeds_numbers": "奖品号码",
  "no_award": "无奖",
  "you_hit_number": "您答对了 {{qty}} 个号码",
  "you_hit_numbers": "您答对了 {{qty}} 个数字",
  "lottery": "彩票",
  "lotteries": "彩票",
  "balls": "球",
  "special_balls": "特价商品",
  "levy": "收藏",
  "major_awarded": "更多球",
  "hits": "点击数",
  "draw_due": "投注限额",
  "close_game": "关闭游戏",
  "draw_due_greater_than_draw_date": "投注限额必须在抽奖前至少 {{timeLimit}} 分钟",
  "draw_date_less_than_now": "抽奖日期必须比当前日期晚至少 {{timeLimit}} 分钟",
  "balance_game": "游戏平衡",
  "your_balance": "您的余额",
  "your_balance_total": "您的总余额",
  "game_package": "{{qty}} 个号码（可赢得 {{prize}} 次）",
  "choose_package": "选择套餐",
  "error_bet": "无法发送投注",
  "do_complete": "去完成",
  "success_bet": "投注已发送",
  "you_bet_number": "您下注 {{qty}} 个数字",
  "winner_msg": "并赢得了 {{prize}} 次",
  "prize": "奖",
  "balance_not_enough": "不充足的资金",
  "addbalance": "为游戏添加平衡",
  "convertbalance": "转换余额",
  "convertbalance_title": "将可用余额转换为游戏余额",
  "can_award": "你能赢",
  "emission": "排放",
  "validation": "验证",
  "participant": "参加者",
  "concourse": "比赛",
  "draw_hours": "开奖时间",
  "qty_dezenas": "数量 十个",
  "bet_amount": "投注金额",
  "share": "分享",
  "shared": "准备分享",
  "simulation": "模拟",
  "valuePay": "支付数量",
  "quotas_paid_success": "付款成功",
  "paids_today": "今天付款",
  "no_paids_today": "今天没有付款",
  "perc": "百分比",
  "define_percent": "设置百分比",
  "success_update": "更新成功",
  "define_percent_next_payment": "设置下一个付款百分比",
  "better": "博彩顾问",
  "lottery_consultant": "助理顾问",
  "create": "创建",
  "doclose": "关闭",
  "lottery_updated": "抽奖已更新",
  "lottery_created": "抽奖已创建",
  "award_packages": "奖励包",
  "qty": "数量",
  "multiplier": "乘数",
  "maxValue": "最大值",
  "limitRepeat": "限制重复",
  "draw_day": "抽奖日",
  "day": "天",
  "hour": "小时",
  "minutes": "分钟",
  "ytb_channel_id": "YouTube 频道 ID",
  "tutorial": "教程",
  "video_url": "视频网址",
  "tutorial_created": "教程已创建",
  "tutorial_deleted": "教程已删除",
  "tutorial_error_delete": "无法删除教程",
  "tutorial_updated": "教程已更新",
  "tutorial_error_update": "无法更新教程",
  "english": "英语",
  "spanish": "西班牙语",
  "portuguese": "葡萄牙语",
  "select_language": "选择语言",
  "language": "语言",
  "add_balance_games": "为游戏添加平衡",
  "consultant_indication": "顾问预约",
  "mensal_activation": "月度激活",
  "sunday": "星期日",
  "monday": "第二",
  "tuesday": "第三",
  "wednesday": "第四",
  "thursday": "第五",
  "friday": "星期五",
  "saturday": "周六",
  "title_binary": "设置每天的双积分百分比",
  "ok_perc_change": "百分比更改成功",
  "cred_deb_title": "贷方或借方余额",
  "send": "发送",
  "voucher_pendent": "优惠券待处理",
  "leader_pendent": "待定领导者",
  "voucher_approved": "优惠券已批准",
  "leader_approved": "认可领导者",
  "no_binary_at_today": "当天没有登记双积分",
  "generate_file": "生成文件",
  "withdrawals_file_created": "提款文件已创建",
  "withdrawals_sended": "已发送提款请求",
  "see_generated_files": "查看生成的文件",
  "files": "文件",
  "file": "文件",
  "conversion": "余额之间的换算",
  "betting_commission": "投注佣金",
  "be_a_consultant": "成为彩票顾问并赚取更多",
  "msg_be_a_consultant": "Lotter 全球利润部门。\n推荐奖金（打包销售）\n双点奖金\n等等。",
  "auto_renovations": "自动续订",
  "auto_renove_binary": "双点自我更新",
  "auto_renove_quotas": "自我更新目标（220%）",
  "auto_renove_anual": "每年自我更新",
  "on": "连接的",
  "change_success": "更改成功",
  "anual": "每年",
  "only_monday": "每周一可提款",
  "transferency": "转移",
  "max_amount": "最大值",
  "info_better": "- 获得 100% 的参赛包和续订积分用于投注。\n\n- 您可以随时充值新的投注积分。\n\n- 参与每个商业计划，并可以从直接和间接推荐中获利、双积分奖金、单级、职业规划和奖励。",
  "info_consultant": "- 没有收到投注积分，创始顾问会出售他的入场包中的投注积分以及 The Lotter 的续订以进行协商，因此在工作日最多可获得其组合包价值的 1%，直到达到 220% 为止如果您愿意，可以续订。\n\n- 您可以随时充值您的投注信用。\n\n- 参与每个商业计划，能够从直接和间接推荐、双点奖金、单级、职业生涯规划和奖励。",
  "confirm": "确认",
  "send_new_token": "确认信息已发送到您的电子邮件后，请单击您的电子邮件进行确认",
  "email_resended": "确认信息已发送至您的邮箱，点击您的邮箱进行确认",
  "expired_send_new_token": "新的确认信息已发送到您的电子邮件，点击您的电子邮件进行确认",
  "become_a_consultant": "升级并获得更多",
  "register_ok": "注册成功",
  "ticket_number": "门票号码",
  "conversion_to_bet": "转换为投注",
  "conversion_balance_games": "余额转换为游戏余额",
  "conversion_balance_lotterprocoin": "余额转换为 激活",
  "conversion_lotterprocoin_games": "LotterPro-硬币到游戏余额转换",
  "conta_corrente": "往来账户",
  "balance_games": "游戏平衡",
  "transf_debit_lotterProCoin": "激活余额转账",
  "deb_saldo_lotterProCoin": "激活 余额借记",
  "transf_credit_lotterProCoin": "将积分转移至 激活",
  "live_now": "现在直播",
  "offline": "等待绘制",
  "tutorials": "教程",
  "official_channel": "官方频道",
  "msg_renove_binary": "您已经完成了最后一个月的激活，并且没有激活自动每月激活。\n这就是您的双积分处于非活动状态的原因。\n支付 25.00 美元添加游戏余额的请求以执行每月激活。",
  "deb_balance_games": "借记游戏余额",
  "cred_saldo_lotterProCoin": "借记 激活 余额",
  "live_hour": "直播时间",
  "choose_side_preferential": "选择首选一侧（当前：{{side}}）",
  "count": "计数",
  "historics": "历史",
  "balance_lotterProCoin": "激活平衡",
  "msg_binary_tree_1": "按下所需用户按钮 1 秒钟，即可查看信息",
  "msg_binary_tree_2": "点击一次所需的用户，即可看到二进制中的下一个用户。",
  "change_security_password": "修改安全密码",
  "info_pass_security": "安全密码用于进行金融交易",
  "redefine_security_password": "重新定义安全密码",
  "redefined_password": "访问密码重置成功！",
  "click_to_enter": "点击进入",
  "title_banner": "欢迎来到{{company}}，{{name}}！",
  "descryption_banner": "复制下面的注册链接并与您的朋友分享以最大化您的收入",
  "no_binaries": "无法显示该用户的网络",
  "invalid_wallet": "钱包无效",
  "valid_wallet": "有效许可证"
}} }; export { messages }