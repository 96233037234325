import axios from './BaseService'

const ORDERS_URL = `${process.env.REACT_APP_API_URL}/ordersmmn/`

export async function getOrders(token, page, limit, search, status, searchUser) {
  const endPoint = `${ORDERS_URL}list${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { status, limit, search, searchUser }, { headers })
  return response.data
}

export async function payOrder(token, order, type) {
  const endPoint = `${ORDERS_URL}payOrder`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { order, type }, { headers })
  return response.data
}
