import { useHistory } from 'react-router-dom'
import { doLogout } from '../../services/AuthService'
import { useEffect } from 'react'

export default function Logout() {
  const history = useHistory()

  function cleanAndRedirect() {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('t')
    sessionStorage.removeItem('rule')
    history.push('/')
  }

  function onLogout() {
    doLogout()
      .then(response => cleanAndRedirect())
      .catch(error => {
        console.error(error)
        cleanAndRedirect()
      })
  }

  useEffect(() => {
    onLogout()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}
