import axios from './BaseService'

const USERS_URL = `${process.env.REACT_APP_API_URL}/users/`
const USERSMMN_URL = `${process.env.REACT_APP_API_URL}/usersmmn/`

export async function getActiveUsers() {
  const response = await axios.get(`${USERS_URL}active`)
  return response.data
}

export async function getActiveUsersParam(param) {
  const response = await axios.get(`${USERS_URL}active?search=${param}`)
  return response.data
}

export async function getUsers(token, page, limit, search) {
  const endPoint = `${USERS_URL}list${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { limit, search }, { headers })
  return response.data
}

export const doVerifyNick = async nick => {
	const endPoint = `${process.env.REACT_APP_API_URL}/auth/verifyNick`;
	const response = await axios.post(endPoint, { nick });
	return response.data;
};

export async function saveUser(id, newUser) {
  let response
  if (id) response = await axios.put(`${USERSMMN_URL}user/${id}`, newUser)
  // else response = await axios.post(USERSMMN_URL, newUser);
  return response.data
}

export async function saveBinaryUser(binary) {
  const response = await axios.post(`${USERSMMN_URL}editBinaryUser`,  binary )
  return response.data
}

export async function deleteUser(id) {
  const response = await axios.delete(`${USERS_URL}${id}`)
  return response.data
}

export async function startUser(id) {
  const response = await axios.post(`${USERS_URL}${id}/start`, {})
  return response.data
}

export async function stopUser(id) {
  const response = await axios.post(`${USERS_URL}${id}/stop`, {})
  return response.data
}

export async function resetUserPassword(id) {
  const response = await axios.post(`${USERS_URL}${id}/reset`, {})
  return response.data
}

export async function getTelegramIds(token, page, limit, search) {
  const endPoint = `${USERS_URL}telegramids${page ? `?page=${page}` : ''}`
  const headers = { 'Content-Type': 'application/json', Authorization: `${token}` }
  const response = await axios.post(endPoint, { limit, search }, { headers })
  return response.data
}
