/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getOrders, payOrder } from '../../services/OrdersService'
import { i18n } from '../../utils/translates/i18n'
import OrderRow from './OrderRow'
import OrderModal from './OrderModal/OrderModal'
import { decrypt } from '../../utils/crypto'

function Orders() {
  const rule = decrypt(sessionStorage.getItem('rule'))
  const defaultLocation = useLocation()
  const history = useHistory()

  const [orders, setOrders] = useState([])
  const [editOrder, setEditOrder] = useState(null)

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [searchUser, setSearchUser] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)

  const doGetOrders = async () => {
    const success = await getOrders(token, page, lim, search, status, searchUser)
    if (success.status) {
      setOrders(success.list.rows)
      setCount(success.list.count)
    }
  }

  useEffect(() => {
    doGetOrders()
  }, [token, page, lim, search, status, searchUser])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const doEditOrder = order => {
    if (!order) return
    setEditOrder(order)
  }

  const doPayOrder = async (order, type) => {
    const success = await payOrder(token, order, type)
    if (success.status) doGetOrders()
    else alert(success.msg)
  }

  return (
    <>
      <Menu />

      {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
        <main className="content">
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('b_orders')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="align-items-center">
                <div className="alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center">
                  <i className="fas fa-search"></i>
                </div>
              </div>
              <div className="input-group mx-3">
                <input
                  type="text"
                  className="form-control"
                  value={searchUser}
                  onChange={e => setSearchUser(e.target.value)}
                  placeholder={`${i18n.t('search')} ${i18n.t('user')}`}
                />
              </div>
              <div className="input-group mx-3">
                <select className="form-select" value={search} onChange={e => setSearch(e.target.value)}>
                  <option value="%">{i18n.t('type')}</option>
                  <option value="investment">{i18n.t('add')}</option>
                  {/* <option value="upgrade">{i18n.t('upgrade')}</option> */}
                  {/* <option value="renew">{i18n.t('renew')}</option> */}
                  {/* <option value="bet">{i18n.t('bet')}</option> */}
                </select>
              </div>
              <div className="input-group mx-3">
                <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                  <option value="">{i18n.t('status')}</option>
                  <option value="1">{i18n.t('waiting')}</option>
                  <option value="2">{i18n.t('partial')}</option>
                  <option value="3">{i18n.t('paid')}</option>
                  <option value="6">{i18n.t('canceled')}</option>
                  {/* <option value="7">{i18n.t('blind_man')}</option>
									<option value="9">{i18n.t('off')}</option>
									<option value="11">{i18n.t('expired')}</option> */}
                </select>
              </div>
              <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
                <option className="bg-light" value="5">
                  5 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="10">
                  10 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="25">
                  25 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="50">
                  50 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="100">
                  100 {i18n.t('registers')}
                </option>
              </select>
            </Pagination>
            <div className="card card-body border-0 shadow table-wrapper table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200">{i18n.t('type')}</th>
                    <th className="border-gray-200">{i18n.t('user')}</th>
                    <th className="border-gray-200">{i18n.t('date')}</th>
                    <th className="border-gray-200">{i18n.t('total')}</th>
                    <th className="border-gray-200">{i18n.t('remaining')}</th>
                    <th className="border-gray-200">{i18n.t('status')}</th>
                    {/* <th className="border-gray-200">{i18n.t('due_date')}</th> */}
                    <th className="border-gray-200">{i18n.t('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((o, i) => (
                    <OrderRow o={o} key={i} editOrder={doEditOrder} rule={rule} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      )}
      {['developer', 'finance', 'support', 'manager', 'admlotteries'].includes(rule) && (
        <OrderModal order={editOrder} payOrder={doPayOrder} rule={rule} />
      )}
    </>
  )
}

export default Orders
