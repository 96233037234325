const messages = {
  es: {
    translations: {
      a_crase: 'a',
      about: 'Acerca de Nosotros',
      above_the_same: 'Arriba exactamente igual',
      action: 'Acción',
      actionTemplates: 'Plantillas de Acciones',
      activate_date: 'Fecha de activación',
      active_automations: 'Automatizaciones Activas',
      active_connections: 'Conexiones Activas',
      active_monitors: 'Monitores Activos',
      active_users: 'Usuarios Activos',
      ad: 'Anuncio',
      add: 'Agregar',
      add_option: 'Agregar opción',
      add_payment: 'Agregar Pago',
      add_question: 'Agregar Pregunta',
      add_receipt: 'Agregar Recibo',
      add_wallet: 'Agregar Billetera',
      address: 'Dirección',
      admin: 'Administración',
      advance: 'Avanzar',
      affected: 'Afectado',
      after: 'Después',
      air_transport: 'Transporte Aéreo',
      ajust_limite: 'Ajuste de Límite',
      ajust_saldo: 'Ajuste de Saldo',
      alert: 'Alerta',
      alerts: 'Alertas',
      all: 'Todos',
      all_departments: 'Todos los Departamentos',
      allies_of: 'Aliados de la',
      allRightsReserved: 'Todos los derechos reservados.',
      almost_there: 'Casi allí',
      already_offset: 'Ya Compensado',
      already_registered: '¿Ya está registrado? Haga clic aquí e inicie sesión',
      amount: 'Cantidad',
      amount_above_balance: 'Saldo insuficiente',
      and: 'y',
      and_more: 'y más',
      answer: 'Respuesta',
      ao: 'a',
      approve: 'Aprobar',
      April: 'abril',
      asc: 'Ascendente',
      at: 'en',
      August: 'Agosto',
      automations: 'Automatizaciones',
      awaiting_email_confirmation: '¡Esperando confirmación por correo electrónico!',
      b_order: 'Orden',
      b_orders: 'Órdenes',
      back: 'Volver',
      bairroInteresse: 'Barrio',
      balance: 'Saldo',
      balance_available: 'Saldo Disponible',
      banned: 'Prohibido',
      before_contact: 'Antes de ponerse en contacto con nosotros',
      binary: 'Binary',
      birth: 'Nacimiento',
      blind_man: 'Anulado',
      body_monthly:
        'Su Cuota Mensual venció y su BOT TRADER fue desactivado, por favor, pague la Orden de Pago y vuelva a disfrutar de sus beneficios',
      bonus_saldo_trader: 'Bono Saldo Trader',
      book_health: 'Book Health',
      but_possible_register:
        'Aún así, es posible hacer su registro, pero no estará relacionado con ningún amigo indicador.',
      buy_volume: 'Volumen de Compra',
      buyer: 'Comprador',
      by: 'por',
      bye: 'Hasta luego',
      call: 'Llamada',
      call_to: 'Llamar a',
      calls: 'Llamadas',
      cancel: 'Cancelar',
      canceled: 'Cancelado',
      career_plan: 'Plan de Carrera',
      casa_verde_amarela: 'Casa Verde Amarilla',
      cash_payment: 'Pago en efectivo',
      cash_payment_return: 'Devolución de pago en efectivo',
      cash_receipt: 'Recibo en efectivo',
      change_indicant: 'Cambiar Indicador',
      change_photo: 'Cambiar Foto',
      change_plan: 'Cambiar plan',
      check_payment: 'Pago con cheque',
      check_payment_return: 'Devolución de pago con cheque',
      check_receipt: 'Recibo con cheque',
      choose: 'Elegir',
      cidadeInteresse: 'Ciudad',
      city: 'Ciudad',
      claims: 'Reclamaciones',
      clean: 'Limpiar',
      click_and_know: 'Haga clic y conozca',
      click_to_copy_the_wallet: 'Haga clic para copiar la billetera',
      client: 'Cliente',
      clients: 'Clientes',
      close: 'Cierre',
      cnh: 'Licencia de conducir',
      cnhDate: 'Fecha de emisión de la Licencia de conducir',
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ Inválido',
      coin: 'Moneda',
      color: 'Color',
      commercial: 'Comercial',
      company: 'Nombre de la Empresa',
      complement: 'Complemento',
      comporRenda: 'Va a componer ingresos con alguien',
      compRenda: 'Tipo de Comprobación de Ingresos',
      confirm_approve: 'Confirmar aprobación',
      confirm_delete: 'Confirmar eliminación',
      confirm_new_password: 'Confirmar Nueva Contraseña',
      confirm_new_password_security: 'Confirmar nueva contraseña de seguridad',
      confirm_password: 'Confirmar Contraseña de Acceso',
      confirm_password_security: 'Confirmar contraseña de seguridad',
      confirm_your_new_password: 'Vuelva a ingresar su nueva contraseña',
      confirmed: 'Confirmado',
      confirmed_email: 'Email confirmado',
      congratulations: '¡Felicidades!',
      contact: 'Contacto',
      contract: 'Contrato',
      copied: 'Copiado',
      copy: 'Copiar',
      copy_success: 'Copiado exitosamente',
      correspondences: 'Correspondencias',
      countries: 'Países',
      country: 'País',
      cpf: 'CPF',
      cpf_invalid: 'CPF Inválido',
      create_order_success: '¡Pedido creado con éxito!',
      created: 'Registrado en',
      cred: 'Crédito',
      cred_binario: 'Crédito Binary',
      cred_deb: 'Créditos/Débitos',
      cred_game_donate: 'Crédito Game Donate',
      cred_ind_direta: 'Crédito de Indicación Directa',
      cred_ind_indireta: 'Crédito de Indicación Indirecta',
      cred_mandala: 'Crédito de la Mandala',
      cred_pagar_com_saldo: 'Crédito Pagar con Saldo',
      cred_pontos: 'Puntos de crédito',
      cred_recb_trader: 'Comerciante receptor de crédito',
      cred_renovacao: 'Crédito de renovación',
      cred_saldo_disp: 'Saldo disponible de crédito',
      cred_upgrade: 'Crédito de actualización',
      credit_card_payment: 'Pago con tarjeta de crédito',
      credit_card_payment_return: 'Devolución de pago con tarjeta de crédito',
      credit_card_receipt: 'recibo de tarjeta de crédito',
      current: 'Actual',
      current_password: 'Contraseña actual',
      current_wallet: 'Portafolio actual',
      dashboard: 'Panel de control',
      data_added: 'Datos agregados',
      data_saved: 'Datos guardados',
      datas: 'Datos',
      date: 'Fecha',
      date_first_installment: 'Fecha del primer pago',
      ddi: 'Código de país',
      deb_de_trader: 'Deuda del comerciante',
      deb_game_donate: 'Juego Donar Débito',
      deb_saldo: 'Balance de débito',
      deb_saldo_disp: 'Saldo disponible deudor',
      deb_saldo_pendent: 'Débito de saldo pendiente',
      deb_val_indev: 'Débito de monto indebido',
      debit_card_payment: 'Pago con tarjeta de débito',
      debit_card_payment_return: 'Devolución de pago con tarjeta de débito',
      debit_card_receipt: 'Recibo con tarjeta de débito',
      debt: 'Deuda',
      debt_td: 'Débito',
      December: 'Diciembre',
      decImpRenda: 'declarar el impuesto sobre la renta',
      deleted_document: 'El documento fue eliminado de su registro',
      department: 'Departamento',
      dependents: 'Dependientes',
      desc: 'Hacia abajo',
      description: 'Descripción',
      details: 'Detalles',
      dev_econom: 'Desarrollo economico',
      developer: 'Desarrollador',
      directly_indicated: 'Indicado directamente',
      directly_indicateds: 'Referido directamente',
      directs: 'Directo',
      district: 'Barrio / distrito',
      divorced: 'Divorciado',
      doa_men_receb: 'Donación mensual de plataforma recibida',
      doacao_td: 'Donación',
      doctorate_complete: 'Doctorado - Completo',
      doctorate_incomplete: 'Doctorado - Incompleto',
      document: 'Documento',
      document_already_registered: 'Documento ya registrado',
      documents: 'Documentos',
      donate: 'Donar',
      donor: 'Donante',
      dont_know_zip: '¿No sabes el código postal? Haga clic aquí',
      dou_fe: 'Confirmo que la información anterior es correcta.',
      downloads: 'Descargar archivos',
      drop_images: 'Haz clic o arrastra las imágenes aquí.',
      due_date: 'Madurez',
      edit: 'Para editar',
      edit_option: 'Opción de edición',
      edit_question: 'Editar pregunta',
      email: 'Correo electrónico',
      email_already_registered: 'correo electrónico ya registrado',
      email_and_or_password_wrong: 'Correo electrónico y/o contraseña incorrectos!',
      email_for_login: 'Este correo electrónico será su inicio de sesión',
      email_or_password_wrong: 'Correo o contraseña incorrectos',
      employee: 'Empleado',
      employees: 'Empleados',
      empty: 'Vacío',
      enter_your_new_password: 'Introduzca su nueva contraseña',
      entrada: '¿Tiene un valor de entrada? En caso afirmativo, ¿qué valor?',
      entries_until_today: 'Entradas hasta la fecha',
      error_confirm_password: 'La confirmación y la nueva contraseña deben ser iguales.',
      error_email: 'Formato de correo inválido',
      error_password: 'Contraseña incorrecta',
      error_phone: 'Numero de telefono invalido',
      estadoInteresse: 'estado',
      estimate: 'Estimado',
      exchange: 'Reemplazo',
      expired: 'Venció',
      extorno_td: 'Inversión',
      extra: 'Extra',
      extract: 'Extracto',
      extracts: 'Extractos',
      fantasy: 'Fantasía',
      fatherName: 'Nombre del Padre',
      February: 'Febrero',
      fee: 'Cargo por retiro',
      feedback: 'Devolver',
      feminine: 'Femenino',
      fgts: 'Valor de su FGTS',
      field_required: 'Finalización obligatoria',
      final: 'Final',
      finance: 'Financiero',
      financial: 'Financiero',
      financial_payment: 'Pago financiero',
      financial_payment_return: 'Devolución del pago financiero',
      financial_receipt: 'Recibo de financiación',
      financing: 'Financiación',
      financings: 'Financiación',
      first_10_pages: 'Primeras 10 páginas',
      fisica: 'Persona física',
      for_year: 'Por año',
      forget_password: 'Olvidé la contraseña',
      forgot_password: 'Olvido la contraseña',
      form: 'Molde',
      form_of_payment: 'Forma de pago',
      found: 'Encontró',
      founds: 'Encontró',
      free: 'Gratis',
      friend_indicated: 'Recomendado por mis amigos',
      friend_indicateds: 'Recomendado por mis amigos',
      fuel: 'Combustible',
      full_name: 'Nombre completo',
      funcPublico: 'es un servidor publico',
      fundamental_complete: 'Fundamental - Completo',
      fundamental_incomplete: 'Fundamental - Incompleto',
      ganh_diario: 'Ganancias diarias',
      gender: 'Género',
      grand_total: 'Total general',
      group: 'Grupo',
      has_withdraw_today: 'Ya se ha solicitado un retiro hoy',
      high: 'Alto',
      home: 'Comenzar',
      how_create_binance_apikey: 'Cómo crear una clave API en Binance',
      how_it_works: 'Como funciona',
      how_to_call: '¿Cómo quieres que te llamen?',
      id_td: 'Referencia directa',
      images: 'Imágenes',
      in_construction: 'En construcción',
      in_order: 'En orden',
      in_stock: 'En stock',
      indicant: 'Indicador',
      indicated_by: 'Estás siendo referido por',
      indicated_not_found: 'El Referidor indicado no fue encontrado...',
      indicateds: 'Nominados',
      indirects: 'Indirecto',
      info: 'Boletines',
      info_contact: 'Información del contacto',
      initial: 'Hogar',
      insert_new: 'Ingresar nuevo',
      installments: 'Cuotas',
      intelligence: 'Inteligencia',
      interesse: '¿Cuál es la región de interés?',
      invalid_contact: 'Formato de contacto no válido',
      invalid_images: 'Imágenes no válidas',
      invalid_token: 'simbolo no valido',
      invite: 'Invitar',
      invite_friends: 'Invita a tus amigos',
      January: 'Enero',
      July: 'Julio',
      June: 'Junio',
      juridica: 'Persona jurídica',
      know_exact_result: '¿Sabes el valor exacto?',
      lead: 'Juntos estaremos construyendo un nuevo futuro.',
      learn_more: 'Sepa mas',
      left: 'Izquierda',
      left_preferential: 'Prefiriendo el lado izquierdo',
      legalNature: 'Naturaleza jurídica',
      level: 'Nivel',
      limite_80: 'Has alcanzado el {{perc}} de tu límite.\n Realiza una mejora y evita la suspensión de tus ingresos.',
      limite_excedido: 'Límite de débito excedido',
      linear: 'Lineal',
      link_email: 'Haga clic en el enlace enviado al correo electrónico registrado.',
      link_invite_copy: 'Compartir enlace copiado correctamente',
      liquid: 'Líquido',
      list: 'Lista',
      loading: 'Cargando',
      location: 'Ubicación',
      locked: 'Obstruido',
      login: 'Entrar',
      logout: 'Salir',
      low: 'Bajo',
      male: 'Masculino',
      manage_users: 'Administrar usuarios',
      manager: 'Gerente',
      mandala: 'mandala',
      mandalas: 'Mándalas',
      manufacturer: 'Fabricante',
      March: 'Marzo',
      maritalStatus: 'Estado civil',
      market: 'Mercado',
      married: 'Casado',
      master_complete: 'Maestría - Completa',
      master_incomplete: 'Maestría - Incompleta',
      max_payment: 'Pago máximo',
      May: 'Puede',
      message: 'Mensaje',
      messageNotSent: 'No fue posible enviar tu mensaje',
      messageSent: 'Su mensaje ha sido enviado con éxito',
      middle_complete: 'Medio - Completo',
      middle_incomplete: 'Medio - Incompleto',
      min_amount_error: 'El valor debe ser al menos:',
      min_withdraw: 'Monto mínimo de retiro',
      missing: 'Desaparecido',
      mission: 'Misión',
      mission_vision_values: 'Misión, visión y valores',
      model: 'Modelo',
      monitors: 'Monitores',
      monthly: 'Mensualidad',
      motherName: 'Nombre de la madre',
      movements: 'Movimientos',
      msg_renovacao: 'Has alcanzado tu límite de ganancias, renueva tu paquete.',
      my_account: 'Mi cuenta',
      my_wallet: 'Mi billetera',
      mystock: 'Mi acción',
      name: 'Nombre',
      nascMaisVelhoRenda2: '¿Cuál es la fecha de nacimiento del mayor?',
      nationality: 'Nacionalidad (País de nacimiento)',
      naturalness: 'Lugar de nacimiento (Ciudad de nacimiento)',
      nature: 'Naturaleza',
      network: 'Red',
      new: 'Nuevo',
      new_call: 'Nueva convocatoria',
      new_financing: 'Nueva Financiación',
      new_order: 'Nueva orden',
      new_password: 'Nueva contraseña',
      new_password_security: 'Nueva contraseña de seguridad',
      new_seller: 'Nuevo vendedor',
      new_wallet: 'Nueva billetera',
      news: 'Noticias',
      next: 'Próximo',
      nick: 'Inicio de sesión de usuario)',
      nis: 'PIS/NIS',
      nis_already_registered: 'PIS/NIS ya registrado',
      no: 'No',
      no_approve: 'Aún no aprobado',
      no_delete: 'No borres',
      no_literate: 'No alfabetizado',
      no_repeat: 'Los datos no se pueden repetir',
      no_results_found: 'Ningún resultado encontrado',
      no_services_or_products_added: 'No se agregaron servicios ni productos',
      no_settings: 'Ve a Configuración y completa tus datos.',
      none_friends_indicated: 'Aún no he recomendado a ningún amigo',
      not_authorized: 'No autorizado',
      not_found: 'No encontrado',
      not_login: 'No se puede iniciar sesión, verifique sus datos e inténtelo nuevamente.',
      not_orders: 'Sin órdenes de pago',
      not_permission: 'Usted no tiene permiso para acceder a esta página',
      not_registered: '¿Todavía no estas registrado? Haga clic aquí y regístrese',
      not_results: 'No se encontraron registros',
      not_self_register: 'No se puede registrar',
      not_today_entry: 'Todavía no hemos tenido entradas hoy.',
      notfound_ad: 'No se encontraron anuncios',
      November: 'Noviembre',
      number: 'Número',
      obs: 'Comentarios',
      obs_pay:
        'Si su corredor debita tarifas del monto enviado, deberá realizar la corrección manualmente sumando los montos de estas tarifas.',
      October: 'Octubre',
      of: 'en',
      off: 'Apagado',
      on_total: 'Del total',
      open: 'Abierto',
      opening: 'Apertura',
      operation: 'Actuación',
      ordenation: 'Realizar pedidos',
      order: 'Orden',
      order_book: 'Libro de pedidos',
      order_not_alowed_to_pay: 'Solicitud no autorizada para pagar con saldo',
      order_not_found: 'Orden no encontrada',
      order_payed: 'Orden #{{id}} pagado exitosamente',
      orders: 'Peticiones',
      other: 'Otro',
      other_services_or_products: 'Otros servicios o productos',
      paid: 'Pagado',
      paid_date: 'dia de pago',
      partial: 'Parcialmente pagado',
      passport: 'Pasaporte',
      password: 'Contraseña',
      password_security: 'Contraseña de seguridad',
      pay: 'Pagar',
      pay_exact: 'Paga el importe en {{coin}}',
      pay_generate: 'Generar pago',
      pay_selected: 'Pagar seleccionado',
      pay_value_in: 'Pagar el importe en',
      pay_with_balance: 'Pagar con Saldo',
      payable: 'Pagar',
      payer: 'Pagador',
      payment: 'Pago',
      ped_retirada: 'Solicitud de Retiro',
      ped_retirada_gan_diario: 'Solicitud de Retiro de Ganancias Diarias',
      pendent: 'Pendiente',
      pendent_order: 'Tienes pedidos pendientes',
      people: 'Gente',
      person: 'Persona',
      person_found_in_other_prefecture: 'Persona ya empadronada en otro municipio',
      personal: 'Tipo',
      personal_addresses: 'Direcciones',
      personal_contacts: 'Contactos',
      personal_data: 'Datos personales',
      personal_infos: 'Información',
      personal_pcva: 'Programa Casa Verde y Amarilla',
      phone: 'Numero de teléfono',
      photo: 'Fotografía',
      pix: 'FOTO',
      pix_payment: 'Pago en foto',
      pix_payment_return: 'Devolución de pago en PIX',
      pix_receipt: 'Recibir en PIX',
      pl_carreira: 'Plano de carrera',
      plan: 'Departamento',
      plates: 'Platos',
      please_wait: 'Espere por favor',
      plus: 'Más',
      point: 'Punto',
      points: 'Puntos',
      portfolio: 'portafolio',
      pos_complete: 'Lato sensu - Completo',
      pos_incomplete: 'Lato sensu - Incompleto',
      prefecture: 'Ayuntamiento',
      prefecture_addresses: 'Direcciones',
      prefecture_contacts: 'Contactos',
      prefecture_data: 'Datos del Ayuntamiento',
      prefecture_infos: 'Información',
      prefectures: 'Ayuntamientos',
      prev: 'Anterior',
      price: 'Precio',
      print: 'Imprimir',
      privacy_policies: 'Políticas de privacidad',
      private_works: 'Obras Privadas',
      profession: 'Profesión',
      profile: 'Perfil',
      program: 'Programa',
      programs: 'Software',
      project: 'Proyecto',
      projects: 'Proyectos',
      public_works: 'Obras Públicas',
      purchase: 'Compra',
      qualify_binary: 'Calificación de doble punto',
      qualquer: 'Cualquier',
      quantity: 'Cantidad',
      quotation: 'Precio',
      read: 'Leer',
      receivable: 'Para recibir',
      recipient: 'Receptor',
      recommendations: 'Recomendaciones',
      redefine_password: 'Redefinir contraseña',
      register: 'Registro',
      register_people: 'Registro de Personas',
      register_wallet: 'Registre una billetera para realizar retiros',
      registers: 'Registros',
      registers_users: 'Registros de usuarios',
      reject: 'Rechazar',
      remaining: 'Restante',
      remove: 'Para eliminar',
      rendaIndividual: 'Ingreso bruto individual',
      renew: 'Renovar',
      renew_mandala: 'Renovación de mandalas',
      reply: 'Responder',
      reports: 'Informes',
      required_data_not_sent: 'Datos requeridos no enviados',
      resend_email: 'Reenvíe el correo electrónico',
      residential: 'Residencial',
      responded: 'Contestada',
      restricao: '¿Tiene restricciones sobre su nombre? (SPC/Serasa)',
      result: 'Resultado',
      results: 'Resultados',
      resume_data: 'Resumen de datos',
      revoked: 'Derogado',
      rg: 'Registro General (Identidad)',
      rgAgency: 'emisor RG',
      rgDate: 'Fecha de emisión de RG',
      rgUf: 'Estado de RG',
      right: 'Bien',
      right_preferential: 'Prefiriendo el lado derecho',
      roi: 'Ganancias diarias',
      sale: 'Venta',
      saq_duplicado: 'Doble Retiro',
      save: 'Ahorrar',
      schooling: 'Educación',
      search: 'Buscar',
      secretary: 'Secretario',
      security_password: 'Contraseña de seguridad',
      see: 'Para ver',
      see_advents: 'ver los anuncios',
      see_call: 'Ver convocatoria',
      see_details: 'Ver detalles',
      see_details_wallet: 'Ver detalles de la billetera',
      see_financing: 'Ver financiación',
      see_order: 'Ver pedido',
      see_user: 'Ver usuarios',
      select_vehicle: 'Seleccionar vehículo',
      self_register: 'Registro',
      sell_volume: 'El volumen de ventas',
      seller: 'Vendedor',
      send_call: 'Enviar llamada',
      send_to: 'Enviar para',
      September: 'Septiembre',
      settings: 'ajustes',
      share_your_link: 'Comparte tu enlace',
      show: 'Mostrar hasta',
      show_from: 'Mostrando desde',
      side: 'Lado',
      sign_in_to_start_your_session: 'Inicia sesión para iniciar tu sesión',
      sign_up: 'Registro',
      single: 'Soltero',
      sold: 'Vendido',
      state: 'estado',
      status: 'Estado',
      strategies: 'Estrategias',
      street: 'Camino',
      subject: 'Sujeto',
      submit: 'Mandar',
      submit_placeholder: 'Por favor complete su correo electrónico',
      subscribe: 'Suscríbete para recibir nuestras novedades',
      subtitle: 'Subtítulo',
      success_update_user: 'Usuario actualizado exitosamente!',
      success_wallet: 'Portafolio actualizado exitosamente!',
      suggestions: 'Sugerencias',
      superior_complete: 'Superior completo',
      superior_incomplete: 'Superior incompleta',
      supervisor: 'Supervisor',
      support: 'Soporte',
      support_materials: 'Materiales de apoyo',
      suspended: 'Suspendido',
      symbol: 'Símbolo',
      symbols: 'Símbolos',
      system: 'Sistema',
      target: 'Meta',
      telegram_chat_id: 'ID de chat de Telegram',
      tempoCarteira: '¿Cuánto tiempo lleva registrado en su cartera?',
      terms: 'Acepto los términos de uso',
      text_hint_binance:
        'Al crear la clave API en Binance, ingrese la IP en las restricciones de acceso:\n172.105.78.139\nLuego seleccione las opciones:\n HABILITAR OPCIONES EUROPEAS\n HABILITAR COMERCIO PUNTUAL Y MARGEN',
      this_value_in: 'Este valor en',
      ticker_health: 'TickerSalud',
      title: 'Título',
      to: 'Hasta',
      to_pay: 'Apagar',
      to_receive: 'A recibir',
      today_entry: 'La entrada de hoy',
      token_not_found_or_already_used: 'Token no encontrado o ya utilizado',
      total: 'Total',
      total_entry: 'Entrada total',
      traderbot: 'Comerciante de robots',
      transf_credito: 'Transferir crédito',
      transf_debito: 'Transferir Débito',
      transfer_payment: 'Pago por transferencia',
      transfer_payment_return: 'Devolución de pago mediante transferencia',
      transfer_receipt: 'Recibo en transferencia',
      transparency: 'Transparencia',
      tx_adm_cred: 'Administrar impuestos',
      tx_adm_deb: 'Administrar impuestos',
      tx_retirada: 'Cargo por retiro',
      tx_retirada_gan_diario: 'Tarifa de retiro de ganancias diarias',
      type: 'Tipo',
      update: 'Actualizar',
      update_wallet: 'Actualizar billetera',
      upgrade: 'Mejora',
      used: 'Usado',
      user: 'Usuario',
      user_active: 'Usuario activo',
      user_already_exists: '¡El usuario ya existe!',
      user_and_or_password_wrong: '¡Nombre de usuario y/o contraseña incorrectos!',
      user_inactive: 'Usuario inactivo',
      user_not_found: 'Usuario no encontrado',
      user_not_logged: 'Usuario no iniciado sesión',
      user_or_password_wrong: 'usuario o contraseña incorrectos',
      users: 'Usuarios',
      users_list: 'Lista de usuarios',
      valid_images: 'Imágenes válidas',
      valorRenda2: '¿Cuál es el valor del segundo ingreso?',
      value: 'Valor',
      value_donate_on: 'Valor de la donación en',
      value_financed: 'Monto financiado',
      value_installment: 'Valor de la cuota',
      values: 'Valores',
      vehicle: 'Vehículo',
      vehicles: 'Vehículos',
      view_order: 'Ver pedido',
      vision: 'Visión',
      visitor: 'Visitante',
      voucher: 'Vale',
      wait_confirm: 'Esperando confirmacion',
      waiting: 'Espera',
      waiting_email: 'El registro fue exitoso, ahora todo lo que necesitas hacer es confirmar tu correo electrónico.',
      waiting_payment: 'En espera de pago',
      wallet: 'portafolio',
      wallet_generate: 'Generar Portafolio',
      wallets: 'Carteras',
      want_donate: 'quiero donar',
      want_now_more_about: 'quiero saber más sobre',
      warning: 'ATENCIÓN',
      we_are: 'Son',
      webhooks: 'Ganchos web',
      welcome: 'Sea bienvenido',
      who_we_are: 'Quienes somos',
      who_we_are_subtitle: 'Una breve descripción de la',
      widower: 'Viudo',
      will_expire_in: 'caducará en',
      with: 'con',
      withdraw: 'Retiro',
      withdraw_sended_email:
        '¡Retiro solicitado, revise su correo electrónico y haga clic en el enlace para autorizar!',
      withdrawal: 'Retiros',
      withdrawals: 'Retiros',
      year: 'Año',
      years: 'Años',
      yes: 'Sí',
      you_have: 'Tú tienes',
      your_country: 'Su país',
      your_dashboard: 'Este es tu Panel de Control.',
      your_link: 'Su enlace',
      zipcode: 'Código postal',
      withdrawal_sended: 'Retiro enviado',
      withdrawal_pending: '¡Retiro ya solicitado! \nEspere el pago para realizar otro retiro.',
      limite_80_top: 'Has alcanzado {{perc}} tu límite.\n Anticipa tu renovación y evita suspender tus ingresos.',
      game: 'Juego',
      games: 'Juegos',
      winning_numbers: 'Números sorteados',
      your_numbers: 'Tus números',
      add_numbers: 'Agregar números',
      next_draw: 'O sorteiro começará em:',
      can_bet_until: 'Puedes apostar hasta',
      your_bets: 'Tus apuestas',
      add_bet: 'Agregar apuesta',
      bet: 'Apuesta',
      bets: 'Apuestas',
      numbers: 'Números',
      special_numbers: 'Números especiales',
      resume: 'Resumen',
      choosed_numbers: 'Números elegidos',
      choosed_special_numbers: 'Números especiales elegidos',
      bets_finished: 'Apuestas resueltas',
      prize_draw: 'Sorteo de premios',
      balance_network: 'Saldo de la red',
      telegram: 'Telegrama',
      logs: 'Registros',
      release_withdrawals: 'Liberar retiros',
      bot_active: 'Bot de lanzamiento',
      available: 'Disponible',
      verify: 'Verificar',
      rescue: 'Rescate',
      awarded: 'Galardonado',
      rescued: 'Rescatada',
      repeat: 'Repetir',
      draw_date: 'Fecha del sorteo',
      bet_date: 'Fecha de apuesta',
      drawn_numbers: 'Números sorteados',
      awardeds_numbers: 'Números de premio',
      no_award: 'Sin premio',
      you_hit_number: 'Tienes {{qty}} número correcto',
      you_hit_numbers: 'Tienes {{qty}} números correctos',
      lottery: 'Lotería',
      lotteries: 'Loterías',
      balls: 'pelotas',
      special_balls: 'Especiales',
      levy: 'recopilación',
      major_awarded: 'mas bolas',
      hits: 'golpes',
      draw_due: 'Límite para apostar',
      close_game: 'Cerrar juego',
      draw_due_greater_than_draw_date:
        'El límite de apuestas debe ser al menos {{timeLimit}} minutos antes del sorteo.',
      draw_date_less_than_now:
        'La fecha del sorteo debe ser mayor que la fecha actual en al menos {{timeLimit}} minutos',
      balance_game: 'Saldo para juegos',
      your_balance: 'Tu balance',
      your_balance_total: 'Su saldo total',
      game_package: '{{qty}} números (puede ganar {{prize}} veces)',
      choose_package: 'Elige el paquete',
      error_bet: 'No se puede enviar la apuesta',
      do_complete: 'Completar',
      success_bet: 'Apuesta enviada',
      you_bet_number: 'Apuestas {{qty}} números',
      winner_msg: 'y ganó {{prize}} veces',
      prize: 'Otorgar',
      balance_not_enough: 'Fondos insuficientes',
      addbalance: 'Agregar saldo a los juegos',
      convertbalance: 'Convertir saldos',
      convertbalance_title: 'Convertir saldo disponible en saldo de juego',
      can_award: 'Puedes ganar',
      emission: 'Emisión',
      validation: 'Validación',
      participant: 'Partícipe',
      concourse: 'Concurso',
      draw_hours: 'Hora del sorteo',
      qty_dezenas: 'Cantidad de decenas',
      bet_amount: 'Cantidad Apuesta',
      share: 'Para compartir',
      shared: 'Listo para compartir',
      simulation: 'Simulación',
      valuePay: 'Valor a pagar',
      quotas_paid_success: 'Pago exitoso',
      paids_today: 'Pagos realizados hoy',
      no_paids_today: 'No se realizaron pagos hoy',
      perc: 'Porcentaje',
      define_percent: 'Establecer porcentaje',
      success_update: 'Actualización exitosa',
      define_percent_next_payment: 'Se define el porcentaje del siguiente pago',
      better: 'Consultor de apuestas',
      lottery_consultant: 'Consultor Asociado',
      create: 'Crear',
      doclose: 'Cerrar',
      lottery_updated: 'Juego actualizado',
      lottery_created: 'Juego creado',
      award_packages: 'Paquetes de premio',
      qty: 'cant.',
      multiplier: 'multiplicación',
      maxValue: 'Max. valor',
      limitRepeat: 'Limitar repetición',
      draw_day: 'Fecha del sorteo',
      day: 'dia',
      hour: 'hora',
      minutes: 'minutos',
      ytb_channel_id: 'ID del canal de YouTube',
      tutorial: 'Tutorial',
      video_url: 'URL del video',
      tutorial_created: 'Tutorial creado',
      tutorial_deleted: 'Tutorial eliminado',
      tutorial_error_delete: 'No se puede eliminar el tutorial',
      tutorial_updated: 'Tutorial actualizado',
      tutorial_error_update: 'No se puede actualizar el tutorial',
      english: 'Inglés',
      spanish: 'Español',
      portuguese: 'Portugués',
      select_language: 'Seleccione el idioma',
      language: 'Idioma',
      add_balance_games: 'Agregar saldo a los juegos',
      consultant_indication: 'Cita de consultor',
      mensal_activation: 'Activación mensual',
      sunday: 'Domingo',
      monday: 'Segundo',
      tuesday: 'Tercero',
      wednesday: 'Cuatro',
      thursday: 'Quinto',
      friday: 'Viernes',
      saturday: 'Sábado',
      title_binary: 'Establecer porcentajes de puntos duales para cada día',
      ok_perc_change: 'Los porcentajes cambiaron exitosamente',
      cred_deb_title: 'Saldo de crédito o débito',
      send: 'Mandar',
      voucher_pendent: 'Vale pendiente',
      leader_pendent: 'Líder pendiente',
      voucher_approved: 'Cupón aprobado',
      leader_approved: 'Líder aprobado',
      no_binary_at_today: 'No hay ningún Punto Dual registrado ese día',
      generate_file: 'Generar archivo',
      withdrawals_file_created: 'Archivo de retiro creado',
      withdrawals_sended: 'Solicitudes de retiro enviadas',
      see_generated_files: 'Ver archivos generados',
      files: 'Archivos',
      file: 'Archivo',
      conversion: 'Conversión entre saldos',
      betting_commission: 'Comisión de apuestas',
      be_a_consultant: 'Conviértete en Consultor de Lotería y gana mucho más',
      msg_be_a_consultant:
        'The Lotter Global Profit Division.\nBono por recomendación (en ventas de paquetes)\nBono de doble punto\ny mucho más.',
      auto_renovations: 'Renovaciones Automáticas',
      auto_renove_binary: 'Autorrenovación de doble punto',
      auto_renove_quotas: 'Autorenovación de Metas (220%)',
      auto_renove_anual: 'Autorenovación anual',
      on: 'Conectado',
      change_success: 'cambiado con éxito',
      anual: 'Anual',
      only_monday: 'Los retiros estarán disponibles todos los lunes.',
      transferency: 'Transferir',
      max_amount: 'Valor máximo',
      info_better:
        '- Recibe el 100 % de tu paquete de entrada y renovaciones en CRÉDITOS para apostar.\n\n- Puedes recargar nuevos créditos de apuestas cuando lo desees.\n\n- Participa en todos los planes de negocios y puedes ganar con referencias directas e indirectas , Bono Doble Punto, Uninivel, Plan de Carrera y premios.',
      info_consultant:
        '- NO RECIBE CRÉDITOS por apuestas, EL CONSULTOR FUNDADOR vende sus CRÉDITOS por apuestas de su Pack de Entrada y renovaciones para que The Lotter las negocie, y por tanto recibe HASTA el 1% entre semana sobre el valor de su pack hasta llegar al 220% y luego puede renovar si lo desea.\n\n- Puede recargar su crédito de apuestas cuando lo desee.\n\n- Participe en todos los planes de negocios, pudiendo ganar con referencias directas e indirectas, bonificación de puntos duales, uninivel, Plan de Carrera y premios.',
      confirm: 'Confirmar',
      send_new_token:
        'Una vez que la confirmación haya sido enviada a su correo electrónico, haga clic en su correo electrónico para confirmar',
      email_resended: 'Confirmación enviada a su correo electrónico, haga clic en su correo electrónico para confirmar',
      expired_send_new_token:
        'Nueva confirmación enviada a su correo electrónico, haga clic en su correo electrónico para confirmar',
      become_a_consultant: 'Actualízate y obtén mucho más',
      register_ok: 'Registrado correctamente',
      ticket_number: 'Número de boleto',
      conversion_to_bet: 'Conversión a apuesta',
      conversion_balance_games: 'Conversión de saldo a saldo de juego',
      conversion_balance_lotterprocoin: 'Conversión de saldo a Activaciones',
      conversion_lotterprocoin_games: 'Conversión de Activaciones a saldo de juego',
      conta_corrente: 'Cuenta corriente',
      balance_games: 'Equilibrio del juego',
      transf_debit_lotterProCoin: 'Transferencia de saldo de Activaciones',
      deb_saldo_lotterProCoin: 'Débito de saldo de Activaciones',
      transf_credit_lotterProCoin: 'Transferir crédito a Activaciones',
      live_now: 'En vivo',
      offline: 'Esperando dibujo',
      tutorials: 'Tutoriales',
      msg_renove_binary:
        'Ya has completado otro mes de la última activación mensual y no tienes activada la activación mensual automática.\nEs por eso que tu Punto Dual está inactivo.\nPaga una solicitud para agregar saldo de juego por un monto de $25.00 para realizar la activación mensual. .',
      deb_balance_games: 'Debe de saldo de juego',
      cred_saldo_lotterProCoin: 'Creédito de saldo de Activaciones',
      live_hour: 'Hora en vivo',
      choose_side_preferential: 'Elija el lado preferido (actualmente: {{side}})',
      count: 'Conteo',
      historics: 'Historial',
      balance_lotterProCoin: 'Equilibrio de activación',
      msg_binary_tree_1: 'Pulse el usuario deseado durante 1 segundo para ver la información',
      msg_binary_tree_2: 'Pulse una vez sobre el usuario deseado para ver los siguientes en el binario.',
      change_security_password: 'Cambiar Contraseña de Seguridad',
      info_pass_security: 'La contraseña de seguridad se utiliza para realizar transacciones financieras.',
      redefine_security_password: 'Redefinir Contraseña de Seguridad',
      redefined_password: '¡Acceda al restablecimiento de contraseña con éxito!',
      click_to_enter: 'Haga clic para entrar',
      title_banner: '¡Bienvenido a {{company}}, {{name}}!',
      descryption_banner:
        'Copie su enlace de registro a continuación y compártalo con sus amigos para maximizar sus ganancias',
      no_binaries: 'No se puede mostrar la red de este usuario',
      invalid_wallet: 'Cartera no válida',
      valid_wallet: 'Licencia válida',
    },
  },
}
export { messages }
